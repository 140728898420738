'use client'
import { cva, VariantProps } from 'class-variance-authority'
import {
  ClassValue,
  cn,
  iconContainerVariants,
  loadingVariants,
  Spinner,
} from 'tailwind-ui'
import { forwardRef } from 'react'
import type { NavLinkProps } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { Icn, SvgIcon } from '../icon'
import { FadeInRightOutRight } from '../transitions'

const navLinkVariants = cva(
  `inline-flex items-center rounded-full font-medium
    focus-visible:ring-primary-500 focus:outline-none focus-visible:ring
 
    transition-colors duration-75 disabled:cursor-not-allowed`,
  {
    variants: {
      variant: {
        brandKit: {
          true: 'rounded-button !bg-interface-500 !text-brand-invert !font-body border-brand hover:opacity-90 active:opacity-90 hover:bg-interface-400 active:bg-interface-600',
          false: 'rounded-md',
        },
        link: [
          'text-gray-500 dark:text-gray-400',

          '!px-0 !py-0',
          'hover:text-dark',
          '!min-h-[20px]',
        ],
        primary: [
          'bg-primary-500 text-white',

          'hover:bg-primary-600 hover:text-white',
          'active:bg-primary-700',
          'disabled:bg-primary-700',
        ],
        outline: [
          'text-primary-500',
          'border-primary-500 border',
          'hover:bg-primary-50 active:bg-primary-100 disabled:bg-primary-100',
        ],
        ghost: [
          'text-primary-500',
          'shadow-none',
          'hover:bg-primary-50 active:bg-primary-100 disabled:bg-primary-100',
        ],
        light: [
          'bg-white text-gray-700',

          'hover:text-dark hover:bg-gray-100',
          'active:bg-white/80 disabled:bg-gray-200',
        ],
        dark: [
          'bg-gray-900 text-white',

          'hover:bg-gray-800 active:bg-gray-700 disabled:bg-gray-700',
        ],
        default: [
          'bg-gray-100 text-gray-700',

          'hover:text-dark hover:bg-gray-100',
          'active:bg-white/80 disabled:bg-gray-200',
          //dark town
          'dark:bg-gray-900 dark:text-white',

          'dark:hover:bg-gray-800 dark:active:bg-gray-700 dark:disabled:bg-gray-700',
        ],
        ghost_default: [
          'text-gray-500 dark:text-gray-400',
          '!shadow-none',
          'hover:text-dark hover:bg-gray-100',
          'active:bg-white/80 disabled:bg-gray-200',
          'dark:hover:bg-gray-800 dark:active:bg-gray-900 dark:disabled:bg-gray-900',
        ],
        tab: [
          'rounded-none shadow-none whitespace-nowrap border-b-2 py-4 px-0 text-sm gap-2 font-medium flex flex-row',
        ],
      },
      size: {
        lg: ['px-6 py-2', 'text-md md:text-base'],
        base: ['px-3 py-1.5', 'text-sm'],
        sm: ['px-2 py-1', 'text-xs md:text-sm'],
      },
      isActive: {
        true: null,
        false: null,
      },
      isDarkBg: {
        true: null,
        false: null,
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'base',
      isActive: false,
      isDarkBg: false,
    },
    compoundVariants: [
      {
        brandKit: true,
        variant: ['ghost_default', 'link'],
        class: '!bg-transparent !text-brand',
      },
      {
        isActive: true,
        variant: ['default'],
        class: 'dark:bg-gray-700 bg-white/80',
      },
      {
        isActive: true,
        variant: ['primary'],
        class: 'bg-primary-700',
      },
      {
        isActive: true,
        variant: ['ghost_default'],
        class:
          'text-slate-900 dark:text-slate-50  bg-gray-100 dark:bg-gray-800',
      },
      {
        isActive: true,
        isDarkBg: false,
        variant: ['outline'],
        class: 'bg-primary-100',
      },
      {
        isActive: true,
        isDarkBg: true,
        variant: ['outline'],
        class: 'bg-gray-800',
      },
      {
        isDarkBg: true,
        variant: ['outline', 'ghost'],
        class: 'hover:bg-gray-900 active:bg-gray-800 disabled:bg-gray-800',
      },
      {
        isActive: true,
        variant: ['tab'],
        class:
          'border-slate-900 text-slate-900 dark:text-slate-50 dark:border-slate-50',
      },
      {
        isActive: false,
        variant: ['tab'],
        class:
          'border-transparent text-gray-500 dark:text-gray-400 hover:border-gray-300',
      },
      {
        size: 'base',
        variant: ['tab'],
        class: 'px-0 py-4',
      },
    ],
  }
)

export interface NavVariantProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof navLinkVariants> {
  isLoading?: boolean
  leftIcon?: SvgIcon
  rightIcon?: SvgIcon
  disabled?: boolean
  classNames?: {
    leftIcon?: ClassValue
    rightIcon?: ClassValue
  }
}

export const SNavLink = forwardRef<
  HTMLAnchorElement,
  NavVariantProps & NavLinkProps
>(
  (
    {
      children,
      className,
      disabled: buttonDisabled,
      isLoading,
      variant = 'default',
      size = 'base',
      isDarkBg,
      leftIcon: LeftIcon,
      rightIcon: RightIcon,
      classNames,
      ...rest
    },
    ref
  ) => {
    const disabled = isLoading || buttonDisabled
    return (
      <NavLink
        className={({ isActive, isPending }) =>
          cn(
            navLinkVariants({ variant, size, isDarkBg, isActive, className }),
            {
              ['relative  cursor-wait disabled:cursor-wait']:
                isLoading || isPending,
              /*
              ['[&>.content]:bg-gray-100 [&>.content]:text-gray-800']:
                isActive && variant === 'tab',
              ['[&>.content]:px-2']: variant === 'tab',
              */
            }
          )
        }
        disabled={disabled}
        ref={ref}
        {...rest}
      >
        {({ isPending }) => (
          <>
            {LeftIcon ? (
              <div
                className={cn(
                  iconContainerVariants({ size, isLeft: !!children })
                )}
              >
                <Icn
                  icon={LeftIcon}
                  size={size}
                  className={cn(
                    size === 'lg' && 'h-6 w-6',
                    classNames?.leftIcon
                  )}
                />
              </div>
            ) : null}
            {children && (
              <div className={cn('content rounded-md')}>{children}</div>
            )}

            {RightIcon && !(isLoading || isPending) ? (
              <div
                className={cn(iconContainerVariants({ size, isRight: true }))}
              >
                <Icn
                  icon={RightIcon}
                  size={size}
                  className={cn(
                    size === 'lg' && 'h-6 w-6',
                    classNames?.rightIcon
                  )}
                />
              </div>
            ) : null}
            {children && (isLoading || isPending) ? (
              <Spinner variant={variant} className={cn('ml-auto')} />
            ) : null}
          </>
        )}
      </NavLink>
    )
  }
)

SNavLink.displayName = 'SNavLink'

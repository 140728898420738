'use client'
import type { DialogProps } from '@headlessui/react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useMemo } from 'react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { SIconButton } from '../button/s-icon-button'
import { cn, cx } from '../helpers/classnames'

function Modal({
  children,
  isOpen,
  title,
  onClose,
  canDismiss = true,
  size: propsSize = 'md',
  classNames = {
    body: '',
    container: '',
  },
  ...rest
}: {
  children: React.ReactNode
  isOpen: boolean
  title?: string
  size?: 'md' | 'lg' | 'xl' | 'full'
  canDismiss?: boolean
  classNames?: {
    body?: string
    container?: string
  }
} & DialogProps<unknown>) {
  const size = useMemo(() => {
    switch (propsSize) {
      case 'md':
        return 'md:max-w-md'
      case 'lg':
        return 'md:max-w-2xl'
      case 'xl':
        return 'md:max-w-4xl'
      case 'full':
        return 'md:max-w-full'
    }
  }, [propsSize])
  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => {
          /**
           * ignore close
           */
          if (canDismiss) {
            onClose(true)
          }
        }}
        {...rest}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={cx('fixed transform-gpu inset-0 bg-black/80', {
              [' backdrop-blur-xl bg-white/80 dark:bg-black/80']: !title,
              'backdrop-blur-xl bg-gray-50/75 dark:bg-gray-950/75':
                propsSize === 'full',
            })}
          />
        </Transition.Child>

        <div className="h-full overflow-y-auto">
          <Transition.Child
            as="div"
            className={cn(
              'flex min-h-dvh h-full md:justify-center text-center',
              {
                'items-end  md:items-center': propsSize !== 'full',
              },
              classNames.container
            )}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel
              className={cn(
                ' w-full transform overflow-visible    text-left align-middle  transition-all',
                size,
                {
                  ['bg-gray-50 dark:bg-gray-950 shadow-xl ']:
                    !!title && propsSize !== 'full',
                  'rounded-t-2xl md:rounded-2xl border-t md:border':
                    propsSize !== 'full',
                  'min-h-dvh flex flex-col': propsSize === 'full',
                }
              )}
            >
              {title ? (
                <div
                  className={cn(' p-4 px-4 relative  ', {
                    'rounded-t-2xl border-b bg-white dark:bg-black':
                      propsSize !== 'full',
                  })}
                >
                  {canDismiss ? (
                    <div className="absolute right-2 top-0 pt-2  z-10">
                      <SIconButton
                        onClick={() => onClose(true)}
                        icon={XMarkIcon}
                        variant="ghost_default"
                        type="button"
                        className="rounded-2xl"
                      >
                        <span className="sr-only">Close modal</span>
                      </SIconButton>
                    </div>
                  ) : null}

                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 !text-gray-900 dark:!text-gray-200 capitalize"
                  >
                    {title}
                  </Dialog.Title>
                </div>
              ) : (
                <div className="flex items-end justify-end p-6">
                  <SIconButton
                    onClick={() => onClose(true)}
                    icon={XMarkIcon}
                    variant="ghost_default"
                    type="button"
                    size="lg"
                    className="rounded-2xl hover:text-gray-900"
                  >
                    <span className="sr-only">Close modal</span>
                  </SIconButton>
                </div>
              )}
              <div
                className={cn(
                  'p-4 pb-safe flex-1 flex flex-col size-full',
                  classNames.body
                )}
              >
                {children}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export { Modal }

import { useMemo } from 'react'
import { z } from 'zod'
import useWindowWidth from '@/hooks/useWindowWidth'
import { isTouchDevice } from '@/utils/tablet'
import morpheusApi from '../morpheus.slice'

export const userConfigDefault = z.object({
  dark_mode: z.boolean().default(false),
  use_system_colour_mode: z.boolean().default(true),
  action_bar: z
    .object({
      icon: z.boolean().default(true),
      label: z.boolean().default(false),
      draggable: z.boolean().default(true),
      position: z.enum(['top', 'bottom', 'left', 'right']).default('left'),
      vertical: z.boolean().default(false),
      has_updated: z.boolean().default(false),
      is_fixed: z.boolean().default(false),
    })
    .default({
      icon: false,
      label: true,
      draggable: true,
      position: 'bottom',
      has_updated: false,
      vertical: false,
      is_fixed: false,
    }),
})

function getDefaults<Schema extends typeof userConfigDefault>(schema: Schema) {
  return Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => {
      if (value instanceof z.ZodDefault) return [key, value._def.defaultValue()]
      return [key, undefined]
    })
  )
}

export type UserConfigType = z.infer<typeof userConfigDefault>

export const useUserConfig = () => {
  const { data = getDefaults(userConfigDefault) as UserConfigType, isSuccess } =
    morpheusApi.useGetUserConfigQuery(null, {
      refetchOnFocus: true,
    })

  const { width } = useWindowWidth()

  const resData = useMemo(() => {
    const isTouch = isTouchDevice()
    if (width < 764) {
      return {
        ...data,
        isSuccess,
        action_bar: {
          ...data.action_bar,
          position: 'bottom',
          is_fixed: false,
          icon: true,
          label: false,
          vertical: false,
        },
      }
    }

    if (['left', 'right'].includes(data.action_bar.position)) {
      return {
        ...data,
        isSuccess,
        action_bar: {
          ...data.action_bar,
          vertical: true,

          position: data.action_bar.position,
        },
      }
    }
    return {
      ...data,
      isSuccess,
      action_bar: {
        ...data.action_bar,
        vertical: ['left', 'right'].includes(data.action_bar.position),

        position: data.action_bar.position,
      },
    }
  }, [data, isSuccess, width])

  return resData
}

import {
  Button,
  Divider,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Text,
  useBoolean,
  useBreakpointValue,
} from '@chakra-ui/react'
import {
  ArrowPathIcon,
  ArrowRightEndOnRectangleIcon,
  ChatBubbleOvalLeftIcon,
  LifebuoyIcon,
  MoonIcon,
  PlusCircleIcon,
  SunIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid'
import loadable from '@loadable/component'
import { format, parseISO } from 'date-fns'
import { useEffect } from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import {
  Avatar,
  Modal,
  SButton,
  SIconButton,
  SNavLink,
  StampedeIcon,
  StampedeWordmark,
  Tooltip,
  cx,
} from 'tailwind-ui'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import {
  orgNavigationItems,
  profileOrgNavigationItems,
} from '@/components/SideNav/menu-config'
import { usePathname } from '@/helpers/router'
import { zEVisibility } from '@/helpers/zendesk'
import { useColorMode } from '@/hooks/use-color-mode'
import useUser from '@/hooks/useUser'
import { defaultButtonClasses } from '@/pages/[org_id]/action-bar'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import { useUserConfig } from '@/state/morpheus/user-config/helpers'
import { gravatarImage } from '@/utils/common'
import { isInStandaloneMode } from '@/utils/helpers/pwa'
import { getColorFromString } from '@/utils/less'

const AddLocationPage = loadable(
  () =>
    import(
      /* webpackChunkName: "add-location" */ '@/components/LocationsPage/AddLocationPage'
    )
)

// const menuItems = AccountMenuItems.map(generateSubMenuItem).filter(
//   (item) => item.key !== '/resource'
// )

function ProfileNav() {
  const [addVenueModalIsOpen, setAddVenueModalIsOpen] = useBoolean()

  const [isPopover, setIsPopover] = useBoolean()
  const user = useUser()
  const pathname = usePathname()
  const { org_id: orgId } = useParams<{ org_id: string }>()
  const push = useNavigate()
  useEffect(() => {
    setIsPopover.off()
  }, [pathname])

  const [isDark, setIsDark] = useColorMode()

  const [add, { isLoading: isAddVenueLoading }] =
    morpheusApi.useAddVenueMutation()

  const buildDate = '__DATE__'
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const config = useUserConfig()
  return (
    <>
      <Modal
        title="Add a Venue"
        isOpen={addVenueModalIsOpen}
        onClose={setAddVenueModalIsOpen.off}
      >
        <AddLocationPage toggleModal={setAddVenueModalIsOpen.off} />
      </Modal>

      <Menu
        isOpen={isPopover}
        onClose={setIsPopover.off}
        onOpen={setIsPopover.on}
        placement={isMobile ? 'auto' : 'right-start'}
        gutter={8}
        strategy="fixed"
      >
        {!config.action_bar.is_fixed || config.action_bar.icon ? (
          <MenuButton
            as={SIconButton}
            size="lg"
            key="profile-nav"
            icon={StampedeIcon}
            isActive={isPopover}
            className={cx(
              'group group-[.dragging]:pointer-events-none',
              defaultButtonClasses,
              {
                active: isPopover,
              }
            )}
            isRound
            variant="ghost_default"
            classNames={{
              icon: 'w-8 h-8 overflow-visible transition-transform	group-[.active]:fill-gray-900 group-focus:fill-gray-900 fill-white dark:group-[.active]:fill-gray-100 dark:group-focus:fill-gray-100 dark:fill-black group-hover:rotate-12 group-hover:scale-200',
            }}
          />
        ) : (
          <MenuButton
            as={SButton}
            key="profile-nav"
            size="lg"
            isActive={isPopover}
            className={cx(
              'group group-[.dragging]:pointer-events-none h-12',
              defaultButtonClasses,
              {
                active: isPopover,
              }
            )}
            variant="ghost_default"
          >
            Stampede
          </MenuButton>
        )}

        <Portal>
          <MenuList
            zIndex={51}
            className="!max-lg:w-full min-w-64 flex space-y-2 flex-col !rounded-xl !pt-4 !pb-0 !bg-gray-100/75 dark:!bg-gray-800/75 !backdrop-blur-lg"
          >
            <div className="space-y-4 px-4">
              <div className="items-center flex gap-4 ">
                <Avatar
                  className="w-12 h-12  rounded-full"
                  src={gravatarImage(user?.email)}
                  style={{
                    backgroundColor: getColorFromString(user?.email),
                  }}
                />
                <div>
                  <div className="text-sm font-medium leading-6  font-['Montserrat']">
                    {user?.first} {user?.last}
                  </div>
                  <div className="-mt-1 text-xs font-light leading-6  font-['Montserrat']">
                    {user?.email}
                  </div>
                </div>
                <SIconButton
                  icon={XMarkIcon}
                  size="xs"
                  variant="ghost_default"
                  className="self-start"
                  isRound
                  onClick={setIsPopover.off}
                />
              </div>

              <div className="rounded-full p-1 gap-1 items-center  bg-white/75 dark:bg-black/75 backdrop-blur-lg border  inline-flex">
                {profileOrgNavigationItems.map((item) => (
                  <Link to={item.key} key={item.key}>
                    <SIconButton
                      isRound
                      icon={item.icon}
                      variant="ghost_default"
                      aria-label={item.title}
                      className="bg-transparent hover:bg-gray-100/75 dark:hover:bg-gray-800/75 dark:text-gray-200"
                    />
                  </Link>
                ))}
                <SIconButton
                  isRound
                  icon={isDark ? SunIcon : MoonIcon}
                  isActive={isDark}
                  variant="ghost_default"
                  onClick={() => {
                    setIsDark((s) => !s)
                  }}
                  className=" hover:bg-gray-100/75 dark:hover:bg-gray-800/75 dark:text-gray-200"
                />
                <Tooltip title="Toggle Live Chat">
                  <SIconButton
                    isRound
                    icon={ChatBubbleOvalLeftIcon}
                    onClick={() => {
                      if (zEVisibility() !== 'visible') {
                        window.zE('messenger', 'hide')
                      } else {
                        window.zE('messenger', 'show')
                      }
                    }}
                    variant="ghost_default"
                    aria-label="Toggle live chat"
                    className="hidden"
                  />
                </Tooltip>
                <Tooltip title="Toggle Live Chat">
                  <SIconButton
                    isRound
                    icon={ArrowPathIcon}
                    aria-label="Reload Stampede"
                    onClick={async () => {
                      if ('serviceWorker' in navigator) {
                        navigator.serviceWorker
                          .getRegistrations()
                          .then((registrations) => {
                            for (const registration of registrations) {
                              registration.unregister()
                            }
                          })
                      }
                      window.location.reload()
                    }}
                    variant="ghost_default"
                  />
                </Tooltip>
              </div>
            </div>

            <nav
              aria-label="Sidebar"
              className="flex flex-1 space-y-4 flex-col"
            >
              <ul className="flex flex-1 flex-col gap-y-4 ">
                <li>
                  <p className="px-7 font-semibold items-center leading-6 text-gray-800 dark:text-gray-200 hidden gap-2  lg:flex">
                    Organisation
                  </p>
                  <ul className="px-4 pt-2 pb-2 space-y-1" role="list">
                    {orgNavigationItems.map((item) => (
                      <li key={item.title}>
                        <SNavLink
                          className="w-full"
                          leftIcon={item.icon}
                          to={item.key}
                          variant="ghost_default"
                        >
                          {item.title}
                        </SNavLink>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
            <Divider />
            <HStack
              justifyContent="space-evenly"
              px="4"
              w="full"
              className="py-2"
            >
              <Stack
                _hover={{ color: 'brand.500', bg: 'gray.100' }}
                alignItems="center"
                as={Link}
                borderRadius="md"
                p="2"
                spacing="0"
                to="../oauth/logout"
              >
                <Icon as={ArrowRightEndOnRectangleIcon} />
                <Text fontSize="xs" fontWeight="normal">
                  Logout
                </Text>
              </Stack>
              <Stack
                _hover={{ color: 'brand.500', bg: 'gray.100' }}
                alignItems="center"
                as={Link}
                borderRadius="md"
                p="2"
                spacing="0"
                target="_blank"
                to="https://help.stampede.ai"
              >
                <Icon as={LifebuoyIcon} />
                <Text fontSize="xs" fontWeight="normal">
                  Support
                </Text>
              </Stack>

              <Stack
                _hover={{ bg: 'gray.100', color: 'brand.500' }}
                as={Button}
                h="full"
                onClick={() => {
                  toast.promise(add({ orgId }).unwrap(), {
                    loading: `Adding venue...`,
                    success: (data) => {
                      push(`/${orgId}/venues/${data.serial}/settings`)
                      return `Venue added`
                    },
                    error: `Failed to add venue`,
                  })
                }}
                p="2"
                px="3"
                spacing="0"
                variant="ghost"
                isLoading={isAddVenueLoading}
              >
                <Icon as={PlusCircleIcon} />
                <Text fontSize="xs" fontWeight="normal">
                  Venue
                </Text>
              </Stack>
            </HStack>
            <MenuItem isDisabled className="!bg-transparent">
              <HStack justifyContent="center" w="full">
                <Text fontSize="xs" opacity="0.9">
                  Build Time{' '}
                  {format(
                    parseISO(buildDate),
                    TimeFormats.StandardDateMonthYearSmallHHmm
                  )}
                </Text>
              </HStack>
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </>
  )
}

export default ProfileNav

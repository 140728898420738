import { Avatar, Icon, Box, Flex, Link, Text, Tooltip } from '@chakra-ui/react'
import { UserCircleIcon } from '@heroicons/react/16/solid'
import { memo } from 'react'
import { usePush as useNavigate } from '@/helpers/router'
import { invert } from '@/utils/color'
import { firstAndLast, gravatarImage } from '@/utils/common'
import { copyToClipboard } from '@/utils/copy'
import { getColorFromString } from '@/utils/less'

interface ProfileRowType {
  email: string
  first: string
  last: string
  phone?: string
  id?: number | string | any
  link?: string
  opt?: {
    smsOptInAt: string | null
    emailOptInAt: string | null
  }
  fallbackText?: string
  onClick?: () => void
}

function ProfileRow({
  email,
  first,
  last,
  link,
  phone,
  fallbackText,
  ...rest
}: ProfileRowType) {
  const push = useNavigate()
  return (
    <Flex align="center" className="pii-data profile-row">
      <Avatar
        bg={getColorFromString(email)}
        color={invert(getColorFromString(email), true)}
        fontSize="xs"
        fontWeight="900"
        icon={<Icon as={UserCircleIcon} boxSize="28px" />}
        name={firstAndLast(first, last, false)}
        size="sm"
        src={gravatarImage(email)}
      />

      <Box isTruncated ml={2} w="100%">
        <Text
          className="text-black dark:text-white"
          fontSize="xs"
          fontWeight="bold"
          lineHeight="16px"
          m={0}
          whiteSpace="break-spaces"
        >
          {firstAndLast(first, last, true) || fallbackText || 'Someone'}
        </Text>
        {phone && false ? (
          <Tooltip className="pii-data" label={phone}>
            <Link
              display="block"
              fontSize="10px"
              fontWeight="500"
              isTruncated
              lineHeight="16px"
              maxW="170px"
              onClick={
                link
                  ? () => {
                      push(link)
                    }
                  : () => {
                      copyToClipboard(phone)
                    }
              }
              textOverflow="ellipsis"
            >
              {phone}
            </Link>
          </Tooltip>
        ) : null}
        <Tooltip className="pii-data" label={email}>
          <Link
            display="block"
            fontSize="10px"
            fontWeight="500"
            isTruncated
            lineHeight="16px"
            maxW="170px"
            onClick={
              link
                ? () => {
                    push(link)
                  }
                : () => {
                    copyToClipboard(email)
                  }
            }
            textOverflow="ellipsis"
            {...rest}
          >
            {email}
          </Link>
        </Tooltip>
      </Box>
    </Flex>
  )
}

export default memo(ProfileRow)

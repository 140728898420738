import { ChevronLeftIcon } from '@heroicons/react/16/solid'
import { AnimatePresence, motion } from 'framer-motion'
import { type ReactElement, type ReactNode, useEffect, useMemo } from 'react'
import {
  Outlet,
  NavLink,
  matchRoutes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { cn, SIconButton, SNavLink } from 'tailwind-ui'
import Description from 'tailwind-ui/src/typeography/description'
import Heading from 'tailwind-ui/src/typeography/heading'
import useWindowWidth from '@/hooks/useWindowWidth'
import { usePageTitle } from '@/hooks/usePageTitle'
import { useUserConfig } from '@/state/morpheus/user-config/helpers'
import { ScrollArea } from '@/common/scroll-area'

const tabs = [
  { name: 'My Account', href: '#', current: false },
  { name: 'Company', href: '#', current: false },
  { name: 'Team Members', href: '#', current: true },
  { name: 'Billing', href: '#', current: false },
]

export function TitleBar({
  backTo = '..',
  title,
  subtitle = '',
  alwaysShow = false,
  children,
}: {
  title: string
  backTo?: string | boolean
  alwaysShow?: boolean
  children?: ReactElement
  subtitle: string
}) {
  return (
    <div
      className={cn('grid grid-cols-[1fr_60px] gap-2 py-4 z-30 ', {
        'lg:hidden px-4 lg:px-6 items-center': !alwaysShow,

        // ['hidden lg:grid']: typeof backTo !== 'string',
      })}
    >
      <div>
        <Heading>{title}</Heading>
        {subtitle ? <Description>{subtitle}</Description> : null}
      </div>
      {!children ? (
        <div className="justify-self-end flex gap-1">
          {typeof backTo === 'string' ? (
            <SNavLink
              variant="ghost_default"
              className={cn(
                '!h-10 !w-10 !shadow-none rounded-full !border-0 justify-center !bg-transparent'
              )}
              leftIcon={ChevronLeftIcon}
              to={backTo}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        children
      )}
    </div>
  )
}

export interface LinkType {
  value: string
  key?: string
  hidden?: boolean
  icon?:
    | React.ForwardRefExoticComponent<
        Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
          title?: string | undefined
          titleId?: string | undefined
        } & React.RefAttributes<SVGSVGElement>
      >
    | string
  children?: LinkType[]
}

const RenderLinks = ({
  links,
  isNestedNav,
}: {
  links: LinkType[]
  isNestedNav: boolean
}) => {
  return links.map((link, index) => (
    <SNavLink
      end
      leftIcon={typeof link.icon !== 'string' ? link.icon : undefined}
      to={link.key ?? index}
      className={cn(
        'capitalize  rounded-xl fill-gray-700 dark:fill-gray-300 text-nowrap',
        {
          //  ['py-2']: isTouchDevice(),
          'h-11': isNestedNav,
          'h-8': !isNestedNav,
        }
      )}
      size={isNestedNav ? 'lg' : 'base'}
      variant={isNestedNav ? 'ghost_default' : 'ghost_default'}
      key={link.key ?? index}
    >
      <div className="flex items-center ">
        {typeof link.icon === 'string' ? (
          <div className="w-6 h-6 mr-2">
            <img
              className="object-cover h-full w-full rounded-md"
              src={link.icon}
            />
          </div>
        ) : undefined}
        <span className="truncate overflow-hidden">{link.value}</span>
      </div>
    </SNavLink>
  ))
}

const container = {
  hidden: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
  show: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
}

const listItem = {
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
}

function TabbedNavLayout({
  links = [],
  root = '',
  title = '',
  subtitle = '',
  isRootOutlet = false,
  context,
  rightElement,
  backTo = '..',
  outletRequiresContext = false,
  outletClassName = undefined,
  isPadded = false,
  children,
}: {
  links: LinkType[]
  root: string
  title: string
  subtitle: string
  backTo?: string
  isRootOutlet?: boolean
  context?: unknown
  rightElement?: ReactElement
  outletRequiresContext?: boolean
  outletClassName?: string | undefined
  isPadded?: boolean
  children?: ReactNode
  idMap?: Record<string, string>
}) {
  const location = useLocation()

  const { width } = useWindowWidth()
  const isNestedNav = useMemo(() => {
    if (width < 768) {
      return true
    }
    const child = links.filter((item) => (item?.children ?? []).length > 0)
    if (child.length === 0) {
      return true
    }
    return true
  }, [links, width])

  const push = useNavigate()
  const item = matchRoutes(
    links.map(({ key }) => ({
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- allow
      path: `${root}/${key}`,
    })),
    location
  )

  usePageTitle(title)

  const path = useMemo(() => {
    if (!item) return ''
    const rootName = root.split('/').pop()
    const name = item[0].pathname.split('/').pop()

    if (name === rootName) {
      return links.find(({ key }) => key === '')?.value
    }
    return links.find(({ key }) => key === name)?.value ?? ''
  }, [item, links, root])

  useEffect(() => {
    if (isRootOutlet) return
    if (outletRequiresContext && !context) return
    document.body.classList.add('page-has-side-nav')

    return () => {
      document.body.classList.remove('page-has-side-nav')
    }
  }, [isRootOutlet, outletRequiresContext, context])

  const config = useUserConfig()

  console.log({ outletRequiresContext, isRootOutlet, context })

  if (isRootOutlet) {
    if (outletRequiresContext && !context) {
      return null
    }
    return <Outlet key={location.pathname} context={context} />
  }

  if (outletRequiresContext && !context) {
    return null
  }

  return (
    <div className="overflow-hidden flex-1 flex flex-col">
      <div
        className={cn(
          'grid grid-cols-1 relative overflow-y-auto lg:overflow-hidden  lg:min-h-full transition-all duration-300',
          {
            'lg:grid-cols-[320px_1fr] xl:grid-cols-[480px_1fr]': isNestedNav,
          }
        )}
      >
        <ScrollArea className="bg-gray-50/75 dark:bg-gray-900/75">
          <div
            className={cn(
              ' flex flex-col  backdrop-blur-lg h-auto lg:h-full max-lg:sticky xl:max-w-sm xl:ml-auto xl:pr-4  xl:w-full max-lg:top-0 max-lg:z-30',
              {
                'max-lg:border-b': !isNestedNav,
                'lg:overflow-y-auto': isNestedNav,

                'pl-5':
                  config.action_bar.position === 'left' &&
                  !config.action_bar.is_fixed,
              }
            )}
          >
            <div
              className={cn(
                'flex justify-between items-start py-4 px-4 lg:px-6 '
              )}
            >
              <div>
                <motion.div
                  initial={{ x: -50 }}
                  animate={{ x: 0 }}
                  exit={{ x: -50 }}
                  transition={{ duration: 0.5 }}
                  key={`${root}_title`}
                >
                  <Heading>{title}</Heading>
                </motion.div>
                {subtitle ? <Description>{subtitle}</Description> : null}
              </div>
              <motion.div
                initial={{ x: 50 }}
                animate={{ x: 0 }}
                exit={{ x: 50 }}
                transition={{ duration: 0.5 }}
                key={`${root}_backbutton`}
              >
                <SIconButton
                  isRound
                  icon={ChevronLeftIcon}
                  onClick={() => {
                    push(backTo)
                  }}
                  variant="ghost_default"
                />
              </motion.div>
            </div>
            <nav
              className={cn('flex flex-1 pb-2 justify-between space-y-6 px-2', {
                'lg:flex-col sticky top-0': isNestedNav,
                'max-lg:pb-2': !isNestedNav,
              })}
              aria-label="Tabs"
            >
              <motion.div
                variants={container}
                initial="hidden"
                animate="show"
                key={`${root}_nestednav_container`}
                className={cn(
                  'scroll-smooth flex-1 overflow-y-hidden flex w-full',
                  {
                    'lg:flex-col gap-1': isNestedNav,
                    'gap-1': !isNestedNav,
                  }
                )}
              >
                {links
                  .filter((it) => it.hidden !== true)
                  .map((link, index) => (
                    <motion.div
                      variants={listItem}
                      key={!link?.key ? index : link.key}
                      className={cn('flex lg:overflow-hidden', {
                        'lg:flex-col lg:w-full gap-1': isNestedNav,
                        'gap-3 items-center': !isNestedNav,
                      })}
                    >
                      {link.children ? (
                        <>
                          {link.key ? (
                            <NavLink
                              end={link.key === ''}
                              to={link.key}
                              className={({ isActive }) =>
                                cn(
                                  'font-semibold items-center leading-6 text-gray-800 dark:text-gray-200 hidden gap-2 px-4 mt-6 mb-2 lg:flex',
                                  {
                                    'is-active group': isActive,
                                  }
                                )
                              }
                            >
                              <span>{link.value}</span>
                              <div className="w-2 h-2 opacity-0 bg-green-500 border rounded-full group-[.is-active]:opacity-100 hidden" />
                            </NavLink>
                          ) : (
                            <p className="font-semibold items-center leading-6 text-gray-800 dark:text-gray-200 hidden gap-2 px-4 mt-6 mb-2 lg:flex">
                              {link.value}
                            </p>
                          )}

                          {RenderLinks({ links: link.children, isNestedNav })}
                        </>
                      ) : (
                        RenderLinks({ links: [link], isNestedNav })
                      )}
                    </motion.div>
                  ))}
                {rightElement ? (
                  <div className="lg:hidden flex items-center gap-2">
                    {rightElement}
                  </div>
                ) : null}
              </motion.div>
              {rightElement ? (
                <div className=" self-center !max-lg:mt-4 max-lg:hidden">
                  {rightElement}
                </div>
              ) : null}
              <p className="font-semibold capitalize hidden">{path}</p>
            </nav>
          </div>
        </ScrollArea>
        <div
          className={cn(
            'flex-1 content-area flex flex-col overflow-x-hidden lg:overflow-y-auto z-0',
            {
              '': isNestedNav || isPadded,
              'px-0': !isPadded,
            },
            outletClassName
          )}
        >
          {children}
          <AnimatePresence initial>
            <Outlet context={context} />
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}

export default TabbedNavLayout

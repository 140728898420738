import { useState, useEffect, useLayoutEffect } from 'react'
import { useColorMode as useLegacyColorMode } from '@chakra-ui/react'
import Cookies from 'js-cookie'

export const useColorMode = () => {
  const cookieModeClaue =
    Cookies.get('theme') === 'dark' ||
    (!Cookies.get('theme') &&
      window.matchMedia('(prefers-color-scheme: dark)').matches)

  const [isDarkMode, setIsDarkMode] = useState(() => {
    return (
      Cookies.get('theme') === 'dark' ||
      (!Cookies.get('theme') &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    )
  })

  useLayoutEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
      // document.body.classList.add('dark')
      //  localStorage.setItem('theme', 'dark')

      Cookies.set('theme', 'dark')
    } else {
      document.documentElement.classList.remove('dark')
      // document.body.classList.remove('dark')
      // localStorage.setItem('theme', 'light')

      Cookies.set('theme', 'light')
    }
  }, [isDarkMode])

  /**
   *
   *
   * Backward compat support for chakra
   */
  const { setColorMode } = useLegacyColorMode()

  useEffect(() => {
    if (!setColorMode) return
    setColorMode(isDarkMode ? 'dark' : 'light')
  }, [isDarkMode, setColorMode])

  return [isDarkMode, setIsDarkMode] as const
}

'use client'

import * as React from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { cn } from '../helpers/classnames'
const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(
  (
    { className, align = 'center', sideOffset = 4, children, ...props },
    ref
  ) => (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={cn(
          'radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down',
          'z-50 w-48 rounded-xl p-4 shadow-md md:w-56',
          'bg-gray-50/75 dark:bg-gray-900/75 backdrop-blur-lg',

          'border',
          // 'z-50 w-72 rounded-xl space-y-4 border bg-popover p-4 text-popover-foreground shadow-base  outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:bg-gray-900/75 backdrop-blur-lg bg-gray-50/75',
          className
        )}
        {...props}
      >
        <PopoverPrimitive.Arrow className="-mt-4 w-4 h-2 fill-current text-gray-50/75 dark:text-gray-900/75" />
        <div className="space-y-4">{children}</div>
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  )
)
PopoverContent.displayName = PopoverPrimitive.Content.displayName

export { Popover, PopoverTrigger, PopoverContent }

import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'tailwind-ui'
import { forwardRef } from 'react'

const textButtonVariants = cva(
  `button inline-flex items-center justify-center font-semibold
  focus-visible:ring-primary-500 focus:outline-none focus-visible:ring
  transition duration-100 'disabled:cursor-not-allowed disabled:brightness-105 disabled:hover:underline'`,
  {
    variants: {
      variant: {
        primary: [
          'text-primary-500 hover:text-primary-600 active:text-primary-700',
          'disabled:text-primary-200',
        ],
        basic: [
          'text-black dark:text-white hover:text-gray-600 active:text-gray-800',
          'disabled:text-gray-300',
        ],
      },
    },
    defaultVariants: {
      variant: 'basic',
    },
  }
)

export interface TextButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof textButtonVariants> {}

export const STextButton = forwardRef<HTMLButtonElement, TextButtonProps>(
  (
    { children, className, variant, disabled: buttonDisabled, ...rest },
    ref
  ) => {
    return (
      <button
        className={cn(textButtonVariants({ variant, className }))}
        disabled={buttonDisabled}
        ref={ref}
        type="button"
        {...rest}
      >
        {children}
      </button>
    )
  }
)

STextButton.displayName = 'STextButton'

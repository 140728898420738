import type { Analytics } from '@segment/analytics-next'
import { AnalyticsBrowser } from '@segment/analytics-next'

import config from '@/config'
import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'

export const SegmentContext = createContext<Analytics | undefined>(undefined)

const SegmentProvider: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined)

  useEffect(() => {
    const loadAnalytics = async () => {
      if (!config.prod) return
      console.log(config)
      const [response] = await AnalyticsBrowser.load({
        writeKey: 'u2GHkUwvxg0vUTLMWiwxzPafW29rGs5P',
      })
      setAnalytics(response)
    }

    loadAnalytics()
  }, [])

  return (
    <SegmentContext.Provider value={analytics}>
      {children}
    </SegmentContext.Provider>
  )
}

export default SegmentProvider

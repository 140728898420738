import {
  Box,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useBoolean,
} from '@chakra-ui/react'
import { Textarea } from '@chakra-ui/textarea'
import {
  ArrowUturnLeftIcon,
  ChevronDownIcon,
  PencilSquareIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid'
import { FormikProvider, useFormik } from 'formik'
import isEqual from 'lodash/isEqual'
import type { FunctionComponent } from 'react'
import { useEffect } from 'react'
import { SButton, SIconButton, cx } from 'tailwind-ui'
import Label from 'tailwind-ui/src/forms/labels/label'
import inboxApi from '@/state/inbox/inbox.slice'
import { initThread } from '@/state/entities/inbox/inbox.types'
import useGlobal from '@/hooks/useGlobal'
import QuillTextarea from '@/common/RichTextInput/quill'
import EmailContacts from '../context/email/contacts'

interface ThreadMessageBoxProps {
  onChangeSendType: (type: 'messages' | 'notes') => void
  sendType: 'messages' | 'notes'
  onComplete: () => void
  threadId: string
  content?: string
}

/**
 *
 * @todo - put these in another DIR
 */
const ThreadSendMessageBox: FunctionComponent<ThreadMessageBoxProps> = ({
  onChangeSendType,
  sendType,
  onComplete,
  threadId,
  content,
}) => {
  const { orgId } = useGlobal()

  const { data: thread = initThread, isLoading } =
    inboxApi.useGetInboxThreadQuery(
      {
        orgId,
        threadId,
      }
      // {
      //   skip: !threadId,
      // }
    )

  const [createContact] = inboxApi.useCreateEmailContactsOnThreadMutation()

  const [removeContact] = inboxApi.useRemoveEmailContactsOnThreadMutation()

  const [sendEvents] = inboxApi.useSendInboxThreadEventsMutation()

  const contactForm = useFormik({
    initialValues: {
      contacts: thread.contacts ?? [],
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: any = {}
      if (!values.contacts) {
        errors.contacts = 'Contacts missing'
      }
      return errors
    },
    onSubmit: async ({ contacts }, { resetForm }) => {
      console.log({ contacts })

      resetForm()
    },
  })

  const [editEmail, setEditEmail] = useBoolean(
    contactForm.values.contacts.length === 0
  )
  /**
   *
   *
   * @todo - refactor this
   */
  useEffect(() => {
    if (contactForm.values.contacts.length === 0) {
      setEditEmail.on()
    } else {
      setEditEmail.off()
    }
  }, [contactForm.values.contacts.length])

  const formik = useFormik({
    initialValues: {
      description: '',
    },
    validate: (values) => {
      const errors: any = {}
      if (!values.description) {
        errors.description = 'Content missing'
      }
      return errors
    },
    onSubmit: async ({ description }, { resetForm }) => {
      await sendEvents({
        type: sendType,
        description,
        orgId,
        threadId: thread.id,
        format: thread.method === 'email' ? 'html' : 'text',
      })
      onComplete()

      resetForm()
    },
  })

  useEffect(() => {
    if (
      isEqual(contactForm.initialValues.contacts, contactForm.values.contacts)
    )
      return
    console.log({ newContacts: 'hello' }, contactForm.values.contacts)
  }, [contactForm.values.contacts, contactForm.initialValues.contacts])

  useEffect(() => {
    // Update formik values when content changes
    formik.setValues({ ...formik.values, description: content ?? '' })
  }, [])
  return (
    <div
      className={cx('rounded-xl flex flex-col p-4 m-4 space-y-2', {
        'bg-yellow-300 dark:bg-yellow-400': sendType === 'notes',
        'bg-gray-100 dark:bg-gray-900': sendType !== 'notes',
      })}
    >
      <HStack align="center">
        <Box>
          <Menu>
            <MenuButton
              as={SButton}
              leftIcon={
                sendType === 'notes' ? PencilSquareIcon : ArrowUturnLeftIcon
              }
              rightIcon={ChevronDownIcon}
              variant="ghost_default"
              className="capitalize rounded-full"
              size="sm"
            >
              {sendType === 'notes' ? 'note' : thread.method}
            </MenuButton>

            <MenuList>
              <MenuOptionGroup
                onChange={(value) => {
                  onChangeSendType(value as ThreadMessageBoxProps['sendType'])
                }}
                type="radio"
                value={sendType}
                className="capitalize"
              >
                <MenuItemOption fontSize="xs" value="notes">
                  Note
                </MenuItemOption>
                <MenuItemOption
                  fontSize="xs"
                  value="messages"
                  className="capitalize"
                >
                  {thread.method}
                </MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Box>
        {!editEmail &&
          sendType === 'messages' &&
          contactForm.values.contacts.length > 0 && (
            <Link fontSize="sm" onClick={setEditEmail.toggle}>
              {contactForm.values.contacts
                .map((contact) => contact.email)
                .join(', ')}
            </Link>
          )}
      </HStack>
      {editEmail && sendType === 'messages' ? (
        <FormikProvider value={contactForm}>
          <form
            onSubmit={contactForm.handleSubmit as any}
            className="w-full flex gap-2"
          >
            <SIconButton
              aria-label="collapse"
              icon={XMarkIcon}
              isRound
              onClick={setEditEmail.toggle}
              className="w-5 h-5"
              size="sm"
            />
            <Label>To:</Label>
            <EmailContacts
              contacts={contactForm.values.contacts}
              formName="contacts"
              onContactAdd={(cont) => {
                if (!thread?.id) return
                createContact({
                  orgId,
                  threadId: thread.id,
                  data: { ...cont, method: 'email' },
                })
              }}
              onContactRemove={(cont) => {
                if (!cont?.id || !thread?.id) return
                removeContact({
                  orgId,
                  threadId: thread.id,
                  contactId: cont.id,
                })
              }}
            />
          </form>
        </FormikProvider>
      ) : null}
      <FormikProvider value={formik}>
        <form className="flex gap-1" onSubmit={formik.handleSubmit as any}>
          {thread.method === 'email' && (
            <QuillTextarea
              className="w-full bg-transparent px-2 border-0 h-auto min-h-16"
              disabled={!thread}
              onChange={(description) =>
                formik.setFieldValue('description', description)
              }
              placeholder={
                sendType === 'notes'
                  ? 'Write an internal note'
                  : 'Write a message'
              }
              value={formik.values.description}
            />
          )}
          {thread.method === 'sms' && (
            <Textarea
              _focus={{}}
              bg="transparent"
              borderWidth={0}
              h="auto"
              minH="0"
              isDisabled={!thread}
              onChange={({ target }) =>
                formik.setFieldValue('description', target.value)
              }
              p={0}
              placeholder={
                sendType === 'notes'
                  ? 'Write an internal note'
                  : 'Write a message'
              }
              px={2}
              value={formik.values.description}
              variant="unstyled"
            />
          )}
          <div className="self-end">
            <SButton
              variant={sendType === 'notes' ? 'default' : 'primary'}
              disabled={!thread || !formik.isValid}
              isLoading={formik.isSubmitting}
              type="submit"
              size="lg"
              // className="border-0 rounded-full"
              brandKit={sendType !== 'notes'}
            >
              {sendType === 'notes' ? 'Save' : 'Send'}
            </SButton>
          </div>
        </form>
      </FormikProvider>
    </div>
  )
}

export default ThreadSendMessageBox

import {
  Box,
  chakra,
  Divider,
  HStack,
  Stack,
  Text,
  usePrevious,
} from '@chakra-ui/react'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import ThreadEmailBox from '@/components/inbox/messaging/email'
import ThreadMessageBox from '@/components/inbox/messaging/message'
import ThreadNoteBox from '@/components/inbox/messaging/note'
import { format, roundToNearestMinutes } from 'date-fns'
import { AnimatePresence, motion } from 'framer-motion'
import useGlobal from '@/hooks/useGlobal'
import {
  FunctionComponent,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useInView } from 'react-intersection-observer'
import { InboxThreadEventType } from '@/state/entities/inbox/inbox.types'
import { useAppDispatch } from '@/state/helpers/useApp'
import { usePusherSubscription } from '@/utils/pusher'
import ThreadSmsBox from '../messaging/sms'
import ThreadSystemNoteBox from '../messaging/system-note'
import ThreadIncomingFormSubmissionBox from '../messaging/incoming-form-submission'
import inboxApi from '@/state/inbox/inbox.slice'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { initServicePagination } from '@/connect-types/backend/service'
import { getFirst } from '@/utils/common'
import { Skeleton } from 'tailwind-ui'

const MotionBox = chakra(motion.div)

const isEventTypeFromUser = (event: InboxThreadEventType) => {
  return ['Email'].includes(event.eventable_type)
}

/**
 *
 * @todo - put these in another DIR
 */

const ThreadItemBox: FunctionComponent<{
  threadId: string
}> = ({ threadId }) => {
  const { orgId } = useGlobal()

  const [query] = useQueryParams(
    {
      selected_thread: withDefault(StringParam, ''),
    },
    {
      updateType: 'pushIn',
    }
  )

  const [cursor, setCursor] = useState('')

  const { data: thread } = inboxApi.useGetInboxThreadQuery({
    orgId,
    threadId,
  })

  const {
    data: events = initServicePagination<InboxThreadEventType>(),
    isLoading: isEventsLoading,
  } = inboxApi.useGetInboxThreadEventsQuery({
    orgId,
    threadId,
    cursor:
      cursor && cursor.includes(threadId) ? getFirst(cursor.split('_')) : '',
  })

  const dispatch = useAppDispatch()
  const [ref, deferInView] = useInView()
  const inView = useDeferredValue(deferInView)
  const messageContentRef = useRef<HTMLDivElement | null>(null)
  const [showTopDiv, setShowTopDiv] = useState(false)
  const prevCursor = usePrevious(events?.meta.next_cursor)
  const prevLength = usePrevious(events?.data.length)

  useEffect(() => {
    if (!events.meta.next_cursor) return
    const timeout = setTimeout(() => setShowTopDiv(true), 500)
    return () => clearTimeout(timeout)
  }, [events.meta.next_cursor])

  const clearTimerRef = useRef<NodeJS.Timeout>()
  const runs = useRef(0)
  const scrollToBottomOfThread = useCallback(() => {
    if (!messageContentRef.current) return
    if (events.data.length === 0) return
    console.log({ timeout: clearTimerRef, runs })
    if (clearTimerRef.current) {
      clearTimeout(clearTimerRef.current)
    }
    clearTimerRef.current = setTimeout(() => {
      if (!messageContentRef.current) return
      runs.current++
      messageContentRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 500)
  }, [events.data.length])

  useEffect(() => {
    if (isEventsLoading) return
    scrollToBottomOfThread()
  }, [scrollToBottomOfThread, isEventsLoading])

  useEffect(() => {
    if (prevCursor !== events.meta.next_cursor) return
    if (prevLength < events.data.length) {
      scrollToBottomOfThread()
    }
  }, [
    prevCursor,
    prevLength,
    events.data.length,
    events.meta.next_cursor,
    scrollToBottomOfThread,
  ])

  useEffect(() => {
    if (inView && !!events.meta.next_cursor) {
      setCursor(`${events.meta.next_cursor}_${threadId}`)
    }
  }, [inView, events.meta.next_cursor, threadId])

  const getPreviousDate = useCallback(
    (index: number) => {
      if (events.data.length === 0) return ''
      if (!events.data[index]) return ''
      const currentItem = format(
        events.data[index].created_at,
        TimeFormats.StandardDateMonthYear
      )
      if (index === 0) {
        return currentItem
      }
      if (
        format(
          events.data[index - 1]?.created_at,
          TimeFormats.StandardDateMonthYear
        ) !== currentItem
      ) {
        return currentItem
      }
    },
    [events]
  )

  const getPreviousTime = useCallback(
    (index: number) => {
      if (events.data.length === 0) return ''
      if (!events.data[index]) {
        return ''
      }

      const currentItem = format(
        roundToNearestMinutes(events.data[index]?.created_at, {
          nearestTo: 10,
        }),
        TimeFormats.FourHourApple
      )
      if (index === 0) {
        return currentItem
      }
      if (!events.data[index + 1]?.created_at) {
        return currentItem
      }
      if (
        format(
          roundToNearestMinutes(events.data[index + 1]?.created_at, {
            nearestTo: 10,
          }),
          TimeFormats.FourHourApple
        ) !== currentItem
      ) {
        return currentItem
      }
    },
    [events]
  )

  const isEmailSameAsPrev = useCallback(
    (index: number) => {
      if (events.data.length === 0) return ''

      const currentItem = events.data[index]
      if (!currentItem) return ''
      const currentType = currentItem.eventable_type
      const prevItem = events.data[index + 1]
      if (!prevItem) return false
      if (currentType === 'Message' || currentType === 'Note') {
        if (
          'user_email' in currentItem.eventable &&
          'user_email' in prevItem.eventable
        ) {
          return (
            currentItem.eventable.user_email === prevItem.eventable.user_email
          )
        }
      }
    },
    [events]
  )

  const loadingArray = useMemo(() => {
    const length =
      !thread?.id ||
      (isEventsLoading && events.data.length === 0) ||
      events.threadId !== thread?.id
        ? 8
        : 0

    return Array(length).fill(null)
  }, [
    thread?.id,
    isEventsLoading,
    events.data.length,
    events.threadId,
    query.selected_thread,
  ])

  usePusherSubscription<{ id: string; thread_id: string }>(
    `inbox.thread.${threadId}.thread_event.stored`,
    ({ thread_id }) => {
      dispatch(
        inboxApi.util.invalidateTags([{ type: 'thread_events', id: thread_id }])
      )
    }
  )

  return (
    <div className="px-2  flex-1">
      <div>
        {thread?.id === events?.threadId &&
          events.data.map((event, index) =>
            event ? (
              <Box
                key={event.id}
                sx={{
                  '#time': {
                    display: getPreviousTime(index) ? 'block' : 'none',
                  },
                  '#avatar': {
                    display: !isEmailSameAsPrev(index) ? 'flex' : 'none',
                  },
                  '#content': {
                    borderBottomRightRadius: getPreviousTime(index) && 'none',
                  },
                }}
              >
                {index === 1 && !!events.meta.next_cursor && showTopDiv && (
                  <div ref={ref} />
                )}
                {!!getPreviousDate(index) && (
                  <div
                    className="flex justify-between items-center py-5"
                    py={5}
                  >
                    <hr className=" h-0.5 bg-gray-50/25 dark:bg-gray-900/25 w-full" />
                    <Skeleton
                      isLoading={isEventsLoading && events.data.length >= 15}
                    >
                      <p className="mx-4 text-sm whitespace-nowrap text-gray-400">
                        {getPreviousDate(index)}
                      </p>
                    </Skeleton>
                    <hr className=" h-0.5 bg-gray-50/25 dark:bg-gray-900/25 w-full" />
                  </div>
                )}
                <MotionBox
                  layout
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  style={{
                    originX: isEventTypeFromUser(event) ? 0 : 1,
                  }}
                  //@ts-ignore
                  transition={{
                    opacity: { duration: 0.2 },
                    layout: {
                      type: 'spring',
                      bounce: 0.4,
                      duration: index * 0.9,
                    },
                  }}
                >
                  {event.eventable_type === 'Note' && (
                    <ThreadNoteBox event={event.eventable} />
                  )}

                  {event.eventable_type === 'Message' && (
                    <ThreadMessageBox
                      threadId={threadId}
                      eventId={event.id}
                      event={event.eventable}
                    />
                  )}
                  {event.eventable_type === 'Incoming SMS Message' && (
                    <ThreadSmsBox event={event.eventable} threadId={threadId} />
                  )}
                  {event.eventable_type === 'Email' && (
                    <ThreadEmailBox
                      threadId={threadId}
                      event={event.eventable}
                    />
                  )}
                  {event.eventable_type === 'System Note' && (
                    <ThreadSystemNoteBox event={event.eventable} />
                  )}
                  {event.eventable_type === 'Incoming Form Submission' && (
                    <ThreadIncomingFormSubmissionBox event={event.eventable} />
                  )}
                </MotionBox>
              </Box>
            ) : null
          )}
        <div className="flex flex-col gap-4">
          {loadingArray.map((_, index) => (
            <MotionBox
              key={index}
              layout
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              style={{
                originX: index % 2 ? 0 : 1,
              }}
              //@ts-ignore
              transition={{
                opacity: { duration: 0.2 },
                layout: {
                  type: 'spring',
                  bounce: 0.4,
                  duration: index * 0.9,
                },
              }}
            >
              <Stack align={index % 2 ? 'start' : 'end'}>
                <HStack
                  gap={2}
                  w="80%"
                  spacing={0}
                  align="end"
                  flexDir={index % 2 ? 'row' : 'row-reverse'}
                >
                  <Skeleton className="w-8 h-8 rounded-full" />
                  <Skeleton
                    h={`${Math.floor(Math.random() * 100) + 28}px`}
                    className="rounded-xl w-full"
                    style={{
                      height: `${Math.floor(Math.random() * 100) + 28}px`,
                    }}
                  />
                </HStack>
              </Stack>
            </MotionBox>
          ))}
        </div>
      </div>
      <div ref={messageContentRef} />
    </div>
  )
}

export default ThreadItemBox

import { FC, PropsWithChildren } from 'react'
import { FirebaseAppProvider } from 'reactfire'
const firebaseConfig = {
  apiKey: 'AIzaSyBUg3kvoGmBb2VnEeXsiD5VRaoP1O2nJAw',
  authDomain: 'blackbx.firebaseapp.com',
  databaseURL: 'https://blackbx.firebaseio.com',
  projectId: 'project-2129760206881629677',
  storageBucket: 'project-2129760206881629677.appspot.com',
  messagingSenderId: '348453694666',
  appId: '1:348453694666:web:6635895f268b1765959833',
  measurementId: 'G-KVVQ3E7T3E',
}

const FirebaseProvider: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    {children}
  </FirebaseAppProvider>
)

export default FirebaseProvider

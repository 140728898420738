import { Avatar } from '@chakra-ui/avatar'
import { Box, Icon, Image } from '@chakra-ui/react'
import {
  ArrowLeftIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
} from '@heroicons/react/16/solid'
import { AnimatePresence, Variants, motion } from 'framer-motion'
import debounce from 'lodash/debounce'
import { useMemo, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Icn, Input, SNavLink } from 'tailwind-ui'
import Description from 'tailwind-ui/src/typeography/description'
import Heading from 'tailwind-ui/src/typeography/heading'
import useSelectedOrganisation from '@/state/entities/organisations/hooks/useSelectedOrganisation'
import { useAllLocations } from '@/state/entities/locations/hooks/useLocation'
import LayoutNavigationCard from '@/layouts/navigation/cards'
import ContentContainer from '@/layouts/content/container'
import { useVenues } from '@/hooks/useVenue'
import { generateImage } from '@/helpers/image'

export function VenueMarker({ image }: any) {
  return (
    <Avatar
      boxShadow="lg"
      marginLeft="-12px"
      marginTop="-12px"
      name=""
      size="xs"
      src={generateImage(image, 'tiny')}
    />
  )
}

function Venues({ onClose }: { onClose: () => void }) {
  const { serial, org_id } = useParams()
  const { pathname } = useLocation()
  const venues = useAllLocations()
  const [, findAlias] = useVenues()
  const variants = {
    enter: {
      opacity: 1,

      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    exit: {
      opacity: 0,

      transition: {
        staggerChildren: 0.05,
        staggerDirection: 1,
        delayChildren: 0.2,
      },
    },
  }

  const [search, setSearch] = useState('')

  const delayedSearchQuery = useRef(
    debounce((event) => {
      setSearch(event.target.value)
    }, 400)
  ).current

  const filteredVenues = useMemo(() => {
    const finder = venues.filter(
      (location) =>
        (location?.alias ?? '')
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()) ||
        location?.serial
          ?.toLocaleLowerCase()
          .includes(search.toLocaleLowerCase())
    )
    return finder
  }, [search, venues])

  const selectedOrganisation = useSelectedOrganisation()

  if (venues.length === 0) return <></>

  return (
    <ContentContainer className="flex flex-col space-y-6">
      {serial ? (
        <div className="space-y-2">
          <div className="flex justify-between">
            <div>
              <Heading className="text-white">
                {findAlias(serial) ?? serial}
              </Heading>
              <Description>You are here</Description>
            </div>
            <SNavLink
              variant="ghost_default"
              size="lg"
              to={`/${org_id}`}
              leftIcon={ArrowLeftIcon}
              end
            >
              {selectedOrganisation.name}
            </SNavLink>
          </div>
          <div className="flex max-md:flex-col md:inline-flex gap-1 rounded-xl md:rounded-full p-2 bg-gray-50/75 dark:bg-gray-900/75">
            <SNavLink
              variant="ghost_default"
              size="lg"
              to={`/${org_id}/venues/${serial}`}
              end
            >
              Home
            </SNavLink>
            <SNavLink
              size="lg"
              variant="ghost_default"
              to={`/${org_id}/venues/${serial}/inbox`}
            >
              Inbox
            </SNavLink>
            <SNavLink
              size="lg"
              variant="ghost_default"
              to={`/${org_id}/venues/${serial}/bookings`}
            >
              Bookings
            </SNavLink>
            <SNavLink
              size="lg"
              variant="ghost_default"
              to={`/${org_id}/venues/${serial}/loyalty`}
            >
              Loyalty
            </SNavLink>
            <SNavLink
              size="lg"
              variant="ghost_default"
              to={`/${org_id}/venues/${serial}/reviews`}
            >
              Reviews
            </SNavLink>
            <SNavLink
              size="lg"
              variant="ghost_default"
              to={`/${org_id}/venues/${serial}/brand`}
            >
              Brand Kit
            </SNavLink>
            <SNavLink
              size="lg"
              variant="ghost_default"
              to={`/${org_id}/venues/${serial}/wi-fi`}
            >
              Wi-Fi
            </SNavLink>
          </div>
        </div>
      ) : null}
      <div>
        <Heading className="text-white">Venues</Heading>
        <Description>{selectedOrganisation?.name}</Description>
      </div>

      <Input
        onChange={delayedSearchQuery}
        placeholder="Search Venues..."
        size="lg"
        varient="filled"
        className="px-4 rounded-full dark:bg-white font-semibold dark:text-gray-800 bg-black text-gray-50 !ring-0 border-transparent ring-transparent focus:bg-gray-900 dark:focus:bg-gray-100 focus:text-white dark:focus:text-black"
      />

      <AnimatePresence mode="popLayout">
        <motion.div
          variants={variants}
          initial="exit"
          animate="enter"
          exit="exit"
          className="grid  gap-6 md:grid-cols-[repeat(auto-fill,minmax(300px,1fr))] grid-cols-[repeat(auto-fill,minmax(150px,1fr))]"
        >
          {filteredVenues.map((venue) => (
            <LayoutNavigationCard
              key={venue.serial}
              className="min-h-[150px] md:min-h-[300px]"
              name={(venue?.alias || venue?.serial) ?? ''}
              icon={<Icon as={MapPinIcon} boxSize="24px" />}
              bgColor={venue.branding_settings.background ?? ''}
              route={
                serial
                  ? pathname.replace(serial, venue.serial)
                  : `/${org_id}/venues/${venue.serial}`
              }
              onClick={() => {
                if (pathname.includes(serial)) {
                  onClose()
                }
              }}
              cover={venue?.branding_settings?.backgroundImage ?? ''}
            >
              {venue.branding_settings.headerImage ? (
                <Image
                  alt={venue.alias}
                  height={{
                    base: '60px',
                    lg: 90,
                  }}
                  ignoreFallback
                  m="0 auto"
                  objectFit="contain"
                  src={generateImage(
                    venue.branding_settings.headerImage,
                    'header'
                  )}
                  width={{
                    base: '180px',
                    lg: 180,
                  }}
                />
              ) : (
                <Box h={100} />
              )}
            </LayoutNavigationCard>
          ))}
        </motion.div>
      </AnimatePresence>
    </ContentContainer>
  )
  /*
  return (
    <LayoutGroup>
      <Box zIndex="2" flex="1" position="relative" boxSize="full">
        <div
          className={cx(style.overlay, {
            [style.dark]: bg === 'gray.900',
          })}
        ></div>

        <Box className={style.maps}>
          <GoogleMaps
            options={{
              zoomControl: false,
              mapTypeControl: false,
              styles: maptheme,
              draggable: false,
              fullscreenControl: false,
              disableDefaultUI: true,
            }}
            center={center}
            zoom={defaults.zoom}
          >
            {filteredVenues.map((venue) => (
              <OverlayView
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                position={{
                  lat: venue.address?.latitude,
                  lng: venue.address?.longitude,
                }}
                key={venue.serial}
              >
                <VenueMarker image={venue.branding?.header_image} />
              </OverlayView>
            ))}
          </GoogleMaps>
        </Box>

        <Flex
          flexDirection="column"
          h="100%"
          zIndex={3}
          position="relative"
          justify="space-between"
        >
          {selectedLocation && (
            <FadeInWhenVisible
              key="main"
              alignSelf="center"
              mt={4}
              zIndex={3}
              className={style['venue-box']}
              bg={bg}
              p={5}
              borderRadius="lg"
              boxShadow="lg"
              top={10}
              maxW="700px"
            >
              <SimpleGrid columns={2} spacing={8}>
                <Stack justifyContent="space-between">
                  <Stack pt={0}>
                    <Heading size="xs" fontWeight="bold">
                      {selectedLocation.alias || selectedLocation.serial}
                    </Heading>

                    <Text variant="time">
                      From {format(start, TimeFormats.DayMonth)} to the{' '}
                      {format(end, TimeFormats.DayMonth)}{' '}
                      <strong>{selectedLocation.alias}</strong> has had{' '}
                      <strong>{data?.totals.users} customers</strong>,{' '}
                      <strong>{data?.totals.return_users}</strong> were
                      returning customers and{' '}
                      <strong>{data?.totals.opt_in_users}</strong> have opted in
                      to marketing
                    </Text>
                  </Stack>

                  <Input
                    size="lg"
                    leftElement={<FontAwesomeIcon icon={faSearch} />}
                    onChange={delayedSearchQuery}
                    label="Search venues"
                  />
                </Stack>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <BigButton
                    as={Link}
                    to={serial}
                    isLoading={isLoading}
                    icon={<FontAwesomeIcon icon={faCog} />}
                  >
                    Settings
                  </BigButton>

                  <BigButton
                    as={Link}
                    to={`${serial}/design`}
                    icon={<FontAwesomeIcon icon={faFillDrip} />}
                    colorScheme="brand"
                  >
                    Branding
                  </BigButton>

                  <BigButton
                    as={Link}
                    to={`../reports/customers?serial=${serial}`}
                    colorScheme="blue"
                    icon={<FontAwesomeIcon icon={faChartPie} />}
                  >
                    Customers
                  </BigButton>

                  <BigButton
                    as={Link}
                    colorScheme="teal"
                    to={`${serial}/upsell`}
                    icon={<FontAwesomeIcon icon={faSortShapesUp} />}
                  >
                    Upsell
                  </BigButton>
                </SimpleGrid>
              </SimpleGrid>
            </FadeInWhenVisible>
          )}
          <Box position="absolute" bottom={0} w="100%">
            <MotionHStack
              overflow="hidden"
              overflowX="auto"
              spacing={4}
              p={4}
              variants={listVariants}
              initial="hidden"
              h="340px"
              alignItems="flex-end"
              layout
            >
              {venues.map((venue) => (
                <FadeInWhenVisible key={venue.serial} direction="up">
                  <VenueCard
                    venue={venue}
                    isSelected={serial === venue.serial}
                    select={(v) => {
                      setSearch('')
                      setSerial(v.serial)
                    }}
                  />
                </FadeInWhenVisible>
              ))}
              <FadeInWhenVisible direction="up">
                <Box key="add" w="340px" height="180px" pr={4}>
                  <IconButton
                    variant="ghost"
                    boxShadow="xl"
                    size="lg"
                    aria-label="Add venue"
                    boxSize="100%"
                    icon={<PlusIcon />}
                    onClick={() => addLocationModal(true)}
                  />
                </Box>
              </FadeInWhenVisible>
            </MotionHStack>
          </Box>
        </Flex>
      </Box>
    </LayoutGroup>
  )
  */
}

export default Venues

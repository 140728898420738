import type { DeepPartial } from '@/config'
import type { Config } from './config.type'
import domain from './domain'

const config: DeepPartial<Config> = {
  appName: 'Product',
  prod: true,
  url: {
    selfservice: `https://api-selfservice.prd.${domain}`,
    api: `https://api.${domain}`,
    connect: `https://product.${domain}`,
    service: `https://service.prd.${domain}`,
    urlGenerator: `https://url-generator.prd.${domain}`,
    app: `https://app.${domain}`,
    bookings: `https://api-bookings.prd.${domain}`,
    // bookings: 'https://d-5ztx7e6k11.execute-api.eu-west-1.amazonaws.com',
    notifications: `https://notifications.prd.${domain}`,
    reporting: `https://reporting.prd.${domain}`,
    inbox: `https://inbox.prd.${domain}`,
    inbox_email: '@stmpd-mail.com',
    morpheus: `https://morpheus.prd.${domain}`,
    zion: `https://zion.prd.${domain}`,
    agent_smith: `https://agent-smith.prd.stampede.ai`,
    loyalty: `https://loyalty.prd.${domain}`,
    loyalty_end_user: `https://loyalty.${domain}`,
    trinity: `https://trinity.prd.${domain}`,
    nearly: `https://wifi.${domain}`,
    auth: `https://auth.${domain}`,
    integrations: `https://bookings-intergrations.prd.${domain}`,
    reports: `https://reports-api.prd.${domain}`,
    billing: `https://billing.prd.${domain}`,
    accounts: `https://accounts.${domain}`,
    reviews: `https://reviews.${domain}`,
  },
  sockets: {
    booking_items: 'wss://7chxkdwg4h.execute-api.eu-west-1.amazonaws.com/prod',
    pusher_client_key: '3a7fd0e4cdd783ddab0e',
  },
  stripeKey: 'pk_live_zLLToAy7himliOqEes7YRCGu',
  auth: {
    clientId: `${domain}.connect`,
    redirectUri: `https://product.${domain}/code`,
  },
}

export default config

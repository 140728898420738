import { IconButton } from '@chakra-ui/react'
import {
  Box,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  StackDivider,
  Text,
  Icon,
} from '@chakra-ui/react'
import { CheckIcon, InformationCircleIcon } from '@heroicons/react/16/solid'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import { format, formatDistance, roundToNearestMinutes } from 'date-fns'
import { FunctionComponent, useMemo } from 'react'
import {
  EventableOutgoingEmailType,
  InboxThreadEventEventableMessageType,
} from '@/state/entities/inbox/inbox.types'
import { useAppDispatch } from '@/state/helpers/useApp'
import { usePusherSubscription } from '@/utils/pusher'
import { ChatAvatar, ThreadPopover } from './common'
import inboxApi, { dateParse } from '@/state/inbox/inbox.slice'

const formatCleanTime = (date: string | Date = '') => {
  if (!date) return 'never'
  return `${formatDistance(dateParse(date), new Date())} ago`
}

const BlueCheckTime: FunctionComponent<{
  label: string
  date: string | Date | null
}> = ({ label, date }) => {
  return (
    <HStack justify="space-between">
      <Text>{`${label} at ${formatCleanTime(date)}`}</Text>
      <Icon
        boxSize="12px"
        as={CheckIcon}
        color={date ? 'brand.500' : 'bg-muted'}
      />
    </HStack>
  )
}

const BlueCheckStack: FunctionComponent<{
  item: EventableOutgoingEmailType
}> = ({ item }) => {
  return (
    <Stack key={item.id} spacing={1} pr={2}>
      <Text fontWeight="bold">
        {`${item.to_name ?? 'Unknown'} <${item.to_email}>`}
      </Text>
      <BlueCheckTime date={item.sent_at} label="Sent" />
      <BlueCheckTime date={item.delivered_at} label="Delivered" />
      <BlueCheckTime date={item.first_opened_at} label="First seen" />
    </Stack>
  )
}

const ThreadMessageBox: FunctionComponent<{
  event: InboxThreadEventEventableMessageType
  threadId: string
  eventId: string
}> = ({ event, threadId, eventId }) => {
  const dispatch = useAppDispatch()
  const hasSentToAll = useMemo(() => {
    if (event.outgoing_emails.length === 0) return false

    return (
      event.outgoing_emails.filter((item) => !!item.sent_at).length ===
      event.outgoing_emails.length
    )
  }, [event.outgoing_emails])

  const hasDeliveredToAll = useMemo(() => {
    if (event.outgoing_emails.length === 0) return false

    return (
      event.outgoing_emails.filter((item) => !!item.delivered_at).length ===
      event.outgoing_emails.length
    )
  }, [event.outgoing_emails])

  const hasSeenToAll = useMemo(() => {
    if (event.outgoing_emails.length === 0) return false
    return (
      event.outgoing_emails.filter((item) => !!item.first_opened_at).length ===
      event.outgoing_emails.length
    )
  }, [event.outgoing_emails])

  const hasSeenPartial = useMemo(() => {
    if (event.outgoing_emails.length === 0) return false
    return (
      event.outgoing_emails.filter((item) => !!item.first_opened_at).length >= 1
    )
  }, [event.outgoing_emails])

  usePusherSubscription<{ id: string; thread_id: string }>(
    `inbox.thread.${threadId}.thread_event.${eventId}.updated`,
    ({ id, thread_id }) => {
      dispatch(
        inboxApi.util.invalidateTags([{ type: 'thread_events', id: thread_id }])
      )
    }
  )

  return (
    <>
      <HStack align="end" justify="end" ml={24} role="group">
        <Box>
          <Box
            py={1}
            px={5}
            bg="#147efb"
            borderRadius="2xl"
            id="content"
            position="relative"
          >
            <ThreadPopover event={event}>
              <IconButton
                left={0}
                opacity={0}
                transition="ease-in-out left 250ms, ease-in opacity 250ms"
                _groupHover={{
                  opacity: 1,
                  left: '-32px',
                }}
                position="absolute"
                variant="ghost"
                isRound
                icon={<Icon as={InformationCircleIcon} />}
                aria-label="info"
              />
            </ThreadPopover>
            <Text
              color="white"
              dangerouslySetInnerHTML={{ __html: event.description }}
            />
          </Box>
          <Popover trigger="hover">
            <PopoverTrigger>
              <HStack justify="end" spacing={0}>
                <Text variant="desc" textAlign="right" id="time" pr={2}>
                  {format(
                    roundToNearestMinutes(event.created_at, {
                      nearestTo: 10,
                    }),
                    TimeFormats.FourHourApple
                  )}
                </Text>
                <Icon
                  boxSize="12px"
                  as={CheckIcon}
                  color={hasSeenPartial ? 'brand.500' : 'bg-muted'}
                  zIndex={1}
                />
                {hasDeliveredToAll && hasSentToAll && (
                  <Icon
                    left="-6px"
                    position="relative"
                    boxSize="12px"
                    color={hasSeenToAll ? 'brand.500' : 'bg-muted'}
                    as={CheckIcon}
                  />
                )}
              </HStack>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverArrow />

                <PopoverBody pr={0}>
                  <Stack fontSize="xs" divider={<StackDivider />}>
                    {event.outgoing_emails.map((item) => (
                      <BlueCheckStack key={item.id} item={item} />
                    ))}
                    {event.outgoing_emails.length === 0 && (
                      <Text>Message sending</Text>
                    )}
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </Box>

        <Box minW="32px">
          <ChatAvatar
            id="avatar"
            email={event.user_email}
            name={event.user_name}
          />
        </Box>
      </HStack>
    </>
  )
}

export default ThreadMessageBox

'use client'
import { Transition } from '@headlessui/react'

type TransProps = React.ComponentPropsWithoutRef<typeof Transition>

export const FadeInOut = ({
  children,
  show,
  className,
  ...props
}: TransProps) => {
  return (
    <Transition
      enter="transition-opacity ease-out duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity ease-out duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={className}
      show={show}
      {...props}
    >
      {children}
    </Transition>
  )
}

export const FadeInRightOutRight = ({
  children,
  show,
  className,
  ...props
}: TransProps) => {
  return (
    <Transition
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-x-1"
      enterTo="opacity-100 translate-x-0"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 translate-x-1"
      className={className}
      show={show}
      {...props}
    >
      {children}
    </Transition>
  )
}

export const FadeInRightOutLeft = ({
  children,
  show,
  className,
  ...props
}: TransProps) => {
  return (
    <Transition
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-x-1"
      enterTo="opacity-100 translate-x-0"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 -translate-x-1"
      className={className}
      show={show}
      {...props}
    >
      {children}
    </Transition>
  )
}

export const FadeInLeftOutLeft = ({
  children,
  show,
  className,
  ...props
}: TransProps) => {
  return (
    <Transition
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 -translate-x-1"
      enterTo="opacity-100 translate-x-0"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 -translate-x-1"
      className={className}
      show={show}
      {...props}
    >
      {children}
    </Transition>
  )
}

export const FadeInLeftOutRight = ({
  children,
  show,
  className,
  ...props
}: TransProps) => {
  return (
    <Transition
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 -translate-x-1"
      enterTo="opacity-100 translate-x-0"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 translate-x-1"
      className={className}
      show={show}
      {...props}
    >
      {children}
    </Transition>
  )
}

export const FadeInAboveOutBelow = ({
  children,
  show,
  className,
  ...props
}: TransProps) => {
  return (
    <Transition
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 -translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
      className={className}
      show={show}
      {...props}
    >
      {children}
    </Transition>
  )
}

export const FadeInAboveOutAbove = ({
  children,
  show,
  className,
  ...props
}: TransProps) => {
  return (
    <Transition
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 -translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-1"
      className={className}
      show={show}
      {...props}
    >
      {children}
    </Transition>
  )
}

export const FadeInBelowOutBelow = ({
  children,
  show,
  className,
  ...props
}: TransProps) => {
  return (
    <Transition
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
      className={className}
      show={show}
      {...props}
    >
      {children}
    </Transition>
  )
}

export const FadeInBelowOutAbove = ({
  children,
  show,
  className,
  ...props
}: TransProps) => {
  return (
    <Transition
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-1"
      className={className}
      show={show}
      {...props}
    >
      {children}
    </Transition>
  )
}

import config from '@/config'
import { createOffline } from '@redux-offline/redux-offline'
import offlineConfig from '@redux-offline/redux-offline/lib/defaults/index'
import { configureStore } from '@reduxjs/toolkit'
import { PersistConfig, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import agentSmithApi from './agent-smith/agent-smith.slice'
import backendApi from './backend/backend.slice'
import billingApi from './billing/billing.slice'
import bookingApi from './booking/booking.slice'
import inboxApi from './inbox/inbox.slice'
import integrationsApi from './integrations/integrations.slice'
import loyaltyApi from './loyalty/loyalty.slice'
import morpheusApi from './morpheus/morpheus.slice'
import { notificationApi } from './notification/notification.slice'
import { rootReducer, RootState } from './reducers'
import reportsApi from './reports/reports.slice'
import rootSaga from './sagas'
import serviceApi from './service/service.slice'
import wifiApi from './wifi/wifi.slice'
import zionApi from './zion/zion.slice'
import { REHYDRATE } from 'redux-persist'

//
//import storage from 'redux-persist/lib/storage'
// -----------------------------------------------------------------------------
// REDUCER

// TODO: getting a type mismatch error in VSCode, but code compiles properly
/*

  */

const persistConfig: PersistConfig<RootState> = {
  storage,
  key: 'root',
  //transforms: [],
  whitelist: ['auth', 'global'], // We have to explicitly include the reducers to be persisted
  throttle: 250,
}

const configureAppStore = (preloadedState: any = {}) => {
  const sagaMiddleware = createSagaMiddleware()

  const {
    middleware: offlineMiddleware,
    enhanceReducer: offlineEnhanceReducer,
    enhanceStore: offlineEnhanceStore,
  } = createOffline({
    ...offlineConfig,
    persist: false,
  })

  const theStore = configureStore({
    reducer: persistReducer(persistConfig, offlineEnhanceReducer(rootReducer)),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        ignoredActions: [REHYDRATE],
      })
        .concat(sagaMiddleware)
        .concat(offlineMiddleware)
        .concat(backendApi.middleware)
        .concat(serviceApi.middleware)
        .concat(bookingApi.middleware)
        .concat(morpheusApi.middleware)
        .concat(wifiApi.middleware)
        .concat(zionApi.middleware)
        .concat(integrationsApi.middleware)
        .concat(reportsApi.middleware)
        .concat(inboxApi.middleware)
        .concat(billingApi.middleware)
        .concat(loyaltyApi.middleware)
        .concat(agentSmithApi.middleware)
        .concat(notificationApi.middleware),

    preloadedState,
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers({
        autoBatch: { type: 'raf' },
      }).concat(offlineEnhanceStore),

    // enhancers: (defaultEnhancers) => [enhancers, ...defaultEnhancers],
    devTools: !config.prod,
  })

  sagaMiddleware.run(rootSaga)
  /*
  if (import.meta.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => theStore.replaceReducer(rootReducer))
    module.hot.accept('./sagas', () => {
      const getNewSagas = require('./sagas')
      sagaTask.cancel()
      sagaTask.toPromise().then(() => {
        sagaTask = sagaMiddleware.run(getNewSagas.default)
      })
    })
  }
  */

  return theStore
}

export default () => {
  const store = configureAppStore()
  const persistor = persistStore(store)

  return { store, persistor }
}

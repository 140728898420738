import { createApi, retry } from '@reduxjs/toolkit/query/react'
import config from '@/config'
import { buildUrl } from '@/utils/common'
import { axiosBaseQuery } from '../helpers'
import type {
  ReportCustomerChartType,
  ReportCustomerTotalType,
} from './reports.type'

const service = 'reports'

const reportsApi = createApi({
  reducerPath: service,

  baseQuery: retry(
    axiosBaseQuery({
      baseUrl: config.url.reports,
      service,
    }),
    {
      maxRetries: 5,
    }
  ),
  endpoints: (build) => ({
    getCustomers: build.query<
      {
        totals: ReportCustomerTotalType
      },
      { org_id: string }
    >({
      query: ({ org_id }) => ({
        url: `/${org_id}/customers`,
        method: 'GET',
      }),
    }),
    getCustomersByTime: build.query<
      {
        totals: ReportCustomerTotalType
        chart: ReportCustomerChartType[]
      },
      { org_id: string; start: Date; end: Date; serial?: string }
    >({
      query: ({ org_id, start, end, serial }) => ({
        url: buildUrl(`/${org_id}/customers/time`, { start, end, serial }),
        method: 'GET',
      }),
    }),
    getCustomersBySource: build.query<
      {
        totals: ReportCustomerTotalType
        chart: ReportCustomerChartType[]
      },
      { org_id: string }
    >({
      query: ({ org_id }) => ({
        url: `/${org_id}/customers/source`,
        method: 'GET',
      }),
    }),
  }),
})

export default reportsApi

import { Suspense, lazy } from 'react'
import { generateImage } from 'ui/helpers/image'

const Image = lazy(() =>
  import('next/image').catch(() => ({ default: () => <div>Not found</div> }))
)

const HeaderImage = ({ image }: { image: string }) => {
  return (
    <div
      className="self-center justify-self-center hide-in-frame overflow-hidden h-full"
      id="header-image-container"
    >
      {image ? (
        <Suspense fallback={<></>}>
          <div className="relative h-full overflow-hidden max-w-sm px-6 py-2 flex items-center justify-center">
            <Image
              alt="brand-kit-logo"
              className="size-full object-contain"
              height={500}
              id="header-image"
              priority
              src={
                /\.(svg|gif)$/i.exec(image)
                  ? generateImage(image, 'header')
                  : image
              }
              width={500}
            />
          </div>
        </Suspense>
      ) : null}
    </div>
  )
}

export default HeaderImage

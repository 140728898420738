import { cn } from 'tailwind-ui'

function Skeleton({
  className,
  isLoading = true,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { isLoading?: boolean }) {
  return (
    <div
      className={cn(
        ' rounded-2xl',
        { ['animate-pulse bg-gray-200/60 dark:bg-gray-700/60']: isLoading },
        className
      )}
      {...props}
    />
  )
}

export { Skeleton }

import { useEffect } from 'react'
import { toast } from 'sonner'
import { pwaInfo } from 'virtual:pwa-info'
import { useRegisterSW } from 'virtual:pwa-register/react'

console.log({ pwaInfo })
const intervalMS = 5 * 1000 * 60

function ReloadPrompt() {
  // replaced dynamically
  const buildDate = '__DATE__'
  // replaced dyanmicaly
  const reloadSW = '__RELOAD_SW__'
  const toastId = 'reload-toast'

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onOfflineReady: () => {
      console.log('offline-ready')
    },

    async onRegisteredSW(swUrl, r) {
      console.log('sw-registered')
      // @ts-expect-error just ignore
      if (reloadSW === 'true' && r) {
        setInterval(async () => {
          if (r.installing || !navigator) return

          if ('connection' in navigator && !navigator.onLine) {
            return
          }

          const resp = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache',
            },
          })

          if (resp?.status === 200) await r.update()
        }, intervalMS)
      }
    },
    onNeedRefresh: () => {},
    onRegisterError(error) {
      console.log({ error }, 'needRefresh')
      console.log('SW registration error', error, 'needRefresh')
    },
  })

  useEffect(() => {
    if (offlineReady || needRefresh) {
      toast.info(
        offlineReady
          ? `App ready to work offline `
          : `New content available, click on reload button to update.`,
        {
          id: `${toastId}-${offlineReady ? 'offline' : 'refresh'}`,
          duration: needRefresh ? 60000 : 5000,
          // dismissible: needRefresh,
          ...(needRefresh && {
            action: {
              onClick: () => {
                toast.promise(updateServiceWorker(false), {
                  loading: 'Downloading content...',
                  success: 'Update complete',
                  error: 'Failed to download content',
                })
              },
              label: 'Reload',
            },
          }),
        }
      )
    }
  }, [offlineReady, needRefresh, updateServiceWorker])

  return null
}

export default ReloadPrompt

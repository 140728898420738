import { LabelHTMLAttributes, forwardRef } from 'react'
import { cn } from '../..'

const Label = forwardRef<
  HTMLLabelElement,
  LabelHTMLAttributes<HTMLLabelElement>
>((props, ref) => {
  return (
    <label
      {...props}
      className={cn(
        'block text-sm  text-label md:text-gray-400 in-frame:text-interface-400',
        props.className
      )}
      ref={ref}
    />
  )
})

export default Label

import type {
  LinkBoxProps,
  SimpleGridProps,
  StackProps,
} from '@chakra-ui/react'
import {
  Box,
  Button,
  Circle,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PlusIcon,
} from '@heroicons/react/16/solid'
import { cx } from 'class-variance-authority'
import { motion } from 'framer-motion'
import type { FunctionComponent, ReactElement } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { SIconButton, Skeleton, SNavLink } from 'tailwind-ui'
import Description from 'tailwind-ui/src/typeography/description'
import Heading from 'tailwind-ui/src/typeography/heading'
import { ContentContainerMd } from '@/layouts/content/container'
import { generateImage } from '@/helpers/image'
import { BentoGrid } from './bento-grid'

const MLinkBox = motion(LinkBox)
const MotionLink = motion(NavLink)

const variants = {
  in: {
    opacity: 1,
    scale: 1,
    y: 0,
  },
  out: {
    // y: -50,
    scale: 0.8,
    opacity: 0,
  },
  enter: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    //  y: -50,
    scale: 0.8,

    opacity: 0,
  },
}

const stringToId = (str = '') => str.replace(/ /g, '_').toLocaleLowerCase()

export function LayoutNavigationCard({
  route,
  name,
  icon,
  children,
  onClick,
  actions,
  isRounded = true,
  cover,
  bgColor,
  className,
  ...rest
}: {
  route?: string
  onClick?: () => void
  name: string
  icon?: JSX.Element
  actions?: ReactElement
  isRounded?: boolean
  cover?: string
  bgColor?: string
  className?: string
} & StackProps) {
  return (
    <div
      className={cx(
        'row-span-1   group/bento transition duration-200  border border-transparent justify-between flex flex-col space-y-4',
        className
      )}
    >
      <MotionLink
        id={`layout_cards_item_${stringToId(name)}`}
        className={cx(
          ' relative  border group overflow-hidden min-h-48 w-full h-full flex flex-1 flex-col justify-end hover:cursor-pointer',
          {
            'bg-gray-50 dark:bg-gray-950': !bgColor,
            'rounded-1.5-consentric': isRounded,
            //  'aspect-[2/1]': !children,
          }
        )}
        style={{ backgroundColor: bgColor }}
        onClick={onClick}
        to={route}
      >
        <div className="">
          {cover ? (
            <img
              src={generateImage(cover, 'tile')}
              className="object-cover absolute bg-gray-100 dark:bg-gray-800 inset-0 w-full h-full group-hover:scale-125 transition-all duration-500"
              alt={name}
            />
          ) : null}
          <div className="absolute h-full w-full bg-white/20 dark:bg-black/20 group-hover:bg-transparent  inset-0" />
        </div>
        <div
          className={cx(
            ' relative transition-all duration-250 rounded-xl   mx-1.5 my-1.5 overflow-hidden  flex flex-col',

            {
              'h-full justify-end flex-1': Boolean(children),
              'bg-white/75 backdrop-blur-xl dark:bg-black/75 group-hover:bg-gray-500/90':
                !children,
            }
          )}
        >
          {children ? (
            <div
              className={cx(
                'w-full pb-12',
                'absolute flex p-3 flex-col items-center justify-center h-full flex-1  rounded-t-xl',
                'bg-white/15 backdrop-blur-md dark:bg-black/15'
              )}
            >
              {children}
            </div>
          ) : null}
          <div
            className={cx(
              'flex z-10 justify-between items-center pl-3 pr-1.5 py-1.5 rounded-b-xl'
            )}
          >
            <div className="flex gap-2 items-center group-hover:text-white transition-colors duration-250 overflow-hidden fill-gray-700 text-gray-700 dark:fill-gray-50 dark:text-gray-50 group-hover:fill-white">
              {icon ? icon : null}
              <p className="font-semibold text-sm truncate capitalize">
                {name}
              </p>
            </div>
            <div className="flex items-center">
              <div
                className="flex flex-row"
                onClick={(e) => {
                  e.preventDefault()
                }}
              >
                {actions}
              </div>

              <SIconButton
                className=" bg-transparent rounded-1.5-consentric text-black dark:text-white border-transparent left-0 relative group-hover:bg-white group-hover:text-stampede-500 border-2 transition-all duration-500"
                aria-label="go to"
                icon={ArrowRightIcon}
                size="sm"
                variant="ghost_default"
                isRound
              />
            </div>
          </div>
        </div>
      </MotionLink>
    </div>
  )
}

export const LayoutNavigationAddCard: FunctionComponent<
  LinkBoxProps & { title: string; to: string }
> = ({ title, to, ...props }) => (
  <MLinkBox
    _hover={{
      bg: 'bg-muted',
    }}
    alignItems="center"
    alignSelf="center"
    borderColor="muted"
    borderRadius="xl"
    borderStyle="dashed"
    borderWidth={1}
    display="flex"
    h="full"
    justifyContent="center"
    py={12}
    transition="background ease-in-out 250ms"
    variants={variants}
    {...props}
  >
    <LinkOverlay as={Link} to={to}>
      <Stack align="center" spacing={3}>
        <Circle bg="brand.500" boxShadow="lg" size="48px">
          <Icon as={PlusIcon} boxSize="24px" color="white" />
        </Circle>
        <Text>{title}</Text>
      </Stack>
    </LinkOverlay>
  </MLinkBox>
)

export function LayoutClickAddCard({
  title,
  onClick,
  ...props
}: LinkBoxProps & { title: string; onClick: () => void }) {
  return (
    <MLinkBox
      _hover={{
        bg: 'bg-muted',
      }}
      alignItems="center"
      alignSelf="center"
      borderColor="muted"
      borderRadius="xl"
      borderStyle="dashed"
      borderWidth={1}
      display="flex"
      h="full"
      justifyContent="center"
      py={12}
      transition="background ease-in-out 250ms"
      variants={variants}
      {...props}
    >
      <LinkOverlay as={Link} onClick={onClick}>
        <Stack align="center" spacing={3}>
          <Circle bg="brand.500" boxShadow="lg" size="48px">
            <Icon as={PlusIcon} boxSize="24px" color="white" />
          </Circle>
          <Text>{title}</Text>
        </Stack>
      </LinkOverlay>
    </MLinkBox>
  )
}

const gvariants = {
  in: {
    transition: { staggerChildren: 0.17, delayChildren: 0.3 },
  },
  out: {
    transition: { staggerChildren: 0.05, staggerDirection: 1 },
  },
}

const tvariants = {
  in: {
    y: 0,

    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  out: {
    y: -150,

    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

export function CardsTitle({
  title,
  subTitle,
  goBack = '',
}: {
  title?: string
  subTitle?: string
  goBack?: string
}) {
  return (
    <HStack
      alignItems={{ base: 'start', md: 'center' }}
      flexDir={{ base: 'column', md: 'row' }}
      justify="space-between"
    >
      {Boolean(goBack) && (
        <Box flex="1">
          <Button
            as={Link}
            leftIcon={<Icon as={ArrowLeftIcon} />}
            to=".."
            variant="ghost"
          >
            {goBack}
          </Button>
        </Box>
      )}

      <Box
        animate="in"
        as={motion.div}
        flex="1"
        initial="out"
        textAlign={{
          base: 'left',
          md: 'left',
        }}
        variants={tvariants}
      >
        {subTitle ? <Description>{subTitle}</Description> : null}
        {title ? <Heading>{title}</Heading> : null}
      </Box>
      {Boolean(goBack) && <Spacer />}
    </HStack>
  )
}

export const gridItemProps = (
  image?: string | null,
  rotate = true,
  rounded = true
) => {
  if (!image) return {}
  return {
    _groupHover: {
      ':before': {
        transform: rotate ? 'rotate(7deg) scale(1.25)' : 'scale(1.25)',
      },
    },

    sx: {
      ':before': {
        ...(rounded && {
          borderRadius: {
            base: 'md',
            lg: 'xl',
          },
        }),

        bgColor: 'bg-surface',
        content: '""',
        boxSize: 'full',
        zIndex: 0,
        backgroundSize: 'cover',
        position: 'absolute',
        transform: rotate ? 'rotate(9deg) scale(1.2)' : 'scale(1)',
        transition: 'transform ease-in 250ms',
        backgroundPosition: 'center',
        bgImage: generateImage(image, 'tile'),
      },
    },
  }
}

export function LayoutCardsGrid({ className, ...props }: SimpleGridProps) {
  return (
    <BentoGrid
      className={cx(
        'grid grid-cols-1 md:grid-cols-2 w-full  lg:grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-4',
        className
      )}
      {...props}
    />
  )
}

export const LayoutNavigationLoader: FunctionComponent<{ height?: string }> = ({
  height = '220px',
}) => {
  return (
    <ContentContainerMd>
      <div className="space-y-1">
        <Skeleton className="w-24 h-5 rounded-lg" />
        <Skeleton className="w-44 h-8 rounded-lg" />
      </div>
      <div className="grid gap-4  grid-cols-[repeat(auto-fill,minmax(200px,1fr))]">
        <Skeleton className="w-full  h-20" />
        <Skeleton className="w-full h-20" />
        <Skeleton className="w-full h-20" />
      </div>
      <div className="grid gap-4  grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
        <Skeleton className="w-full  h-44" />
        <Skeleton className="w-full h-44" />
        <Skeleton className="w-full h-44" />
      </div>
      <div className="grid gap-4  grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
        <Skeleton className="w-full  h-44" />
        <Skeleton className="w-full h-44" />
        <Skeleton className="w-full h-44" />
      </div>
      <div className="grid gap-4  grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
        <Skeleton className="w-full  h-44" />
        <Skeleton className="w-full h-44" />
        <Skeleton className="w-full h-44" />
      </div>
      <div className="grid gap-4  grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
        <Skeleton className="w-full  h-44" />
        <Skeleton className="w-full h-44" />
        <Skeleton className="w-full h-44" />
      </div>
    </ContentContainerMd>
  )
}

export default LayoutNavigationCard

import {
  Box,
  Divider,
  HStack,
  Stack,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FunctionComponent, PropsWithChildren, ReactElement } from 'react'

interface FieldGroupProps extends StackProps {
  title?: string
  description?: string | ReactElement
  extra?: ReactElement
  isOnlyColumn?: boolean
  wrapTitle?: boolean
}

const FieldGroup: FunctionComponent<PropsWithChildren<FieldGroupProps>> = (
  props
) => {
  const {
    title,
    description,
    children,
    extra,
    isOnlyColumn,
    wrapTitle = false,
    ...flexProps
  } = props
  return (
    <Box className="field-group">
      <Stack
        direction={{ base: 'column', md: isOnlyColumn ? 'column' : 'row' }}
        py={6}
        spacing="6"
        {...flexProps}
      >
        <Box minW="3xs" w="full" flex="1">
          {title && (
            <HStack justify="space-between">
              <Text
                as="h2"
                fontWeight="semibold"
                fontSize="lg"
                flexShrink={wrapTitle ? 'unset' : 0}
              >
                {title}
              </Text>
              {extra}
            </HStack>
          )}
          {description && (
            <Text
              variant="desc"
              fontSize="xs"
              flexShrink={0}
              mt={isOnlyColumn ? 0 : 3}
            >
              {description}
            </Text>
          )}
        </Box>
        <VStack width="full" spacing="3" flex="2">
          {children}
        </VStack>
      </Stack>

      <Divider />
    </Box>
  )
}

export default FieldGroup

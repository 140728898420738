import { motion, type Variants, type Variant } from 'framer-motion'
import { type ComponentPropsWithoutRef, forwardRef } from 'react'
import { cn } from 'tailwind-ui'

const variants: Variants = {
  in: { opacity: 0, scale: 0.98, transition: { duration: 0.2 } },
  stay: { opacity: 1, scale: 1 },
  out: {
    scale: 0.98,
    opacity: 0,
  },
}
// eslint-disable-next-line react/display-name
const ContentContainer = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'>
>(({ className, ...rest }, ref) => {
  return (
    <motion.div
      transition={{
        type: 'easeInOut',
        duration: 0.2,
      }}
      initial="in"
      animate="stay"
      whileInView="enter"
      exit="out"
      variants={variants}
      ref={ref}
      className={cn(
        'w-full max-w-6xl mx-auto py-4 px-4 pb-32 space-y-6 relative',
        className
      )}
      {...rest}
    />
  )
})

export function ContentContainerMd(
  props
): React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> {
  const { className, ...rest } = props
  return (
    <div
      className={cn(
        'w-full max-w-5xl mx-auto py-6 px-4  pb-32 space-y-6',
        className
      )}
      {...rest}
    />
  )
}

export default ContentContainer

// Sentry initialization should be imported first!
import 'react-app-polyfill/stable'
import './instrument'

import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { routes } from './routes'
import { cx, Spinner } from 'tailwind-ui'
import * as Sentry from '@sentry/react'
import { sentryCreateBrowserRouter } from './instrument'
import Cookies from 'js-cookie'
import { Provider as ReduxProvider } from 'react-redux'
import store from '@/state/store'
import { PersistGate } from 'redux-persist/integration/react'

const createStore = store()

if (window.location.hostname === 'localhost') {
  //  document.domain = 'localhost'
}

// old site - when we are ready we will force people over to the new stampede site
if (window.location.hostname === 'product.blackbx.io') {
  window.location.href = `https://product.stampede.ai${window.location.pathname}${window.location.search}`
}

if (window.location.hostname.startsWith('connect')) {
  window.location.href = `https://${window.location.hostname.replace(
    'connect',
    'product'
  )}/${window.location.pathname}`
}

const root = createRoot(document.getElementById('root')!)

const router = sentryCreateBrowserRouter(routes)

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose())
}

const isDarkMode =
  Cookies.get('theme') === 'dark' ||
  (!Cookies.get('theme') &&
    window.matchMedia('(prefers-color-scheme: dark)').matches)

const applyMetaColor = (color: string) => {
  const metas = document.getElementsByTagName('meta')
  for (let i = 0; i < metas.length; i += 1) {
    if (metas.item(i).name === 'theme-color') {
      metas.item(i).content = color
    }
  }
}

if (isDarkMode) {
  document.documentElement.classList.add('dark')
  // document.body.classList.add('dark')
  //  localStorage.setItem('theme', 'dark')
  applyMetaColor('#000000')
  Cookies.set('theme', 'dark')
} else {
  document.documentElement.classList.remove('dark')
  // document.body.classList.remove('dark')
  // localStorage.setItem('theme', 'light')
  applyMetaColor('#FFFFFF')
  Cookies.remove('theme')
  // Cookies.set('theme', 'light')
}

root.render(
  <StrictMode>
    <ReduxProvider store={createStore.store}>
      <PersistGate
        loading={
          <div
            className={cx(
              'flex items-center justify-center min-h-screen h-screen w-full flex-1',
              {
                ['bg-black']: isDarkMode,
              }
            )}
          >
            <Spinner />
          </div>
        }
        persistor={createStore.persistor}
      >
        <RouterProvider
          fallbackElement={
            <div
              className={cx(
                'flex items-center justify-center min-h-screen h-screen w-full flex-1',
                {
                  ['bg-black']: isDarkMode,
                }
              )}
            >
              <Spinner />
            </div>
          }
          router={router}
        />
      </PersistGate>
    </ReduxProvider>
  </StrictMode>
)

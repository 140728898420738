export const zE =
  typeof window !== 'undefined' && window.zE !== undefined ? window.zE : null

export const zEElement = document.getElementById('launcher')

export const zEVisibility = () => {
  return zEElement?.parentElement?.parentElement?.style?.visibility
}

export function zEOpen() {
  if (typeof window !== 'undefined' && window.zE) {
    window.zE('messenger', 'open')
  } else {
    console.log('zE not initialised on window')
  }
}

export function zEOpenWithTag(tags: string[]) {
  if (zE) {
    zE('messenger', 'open')
    zE('messenger:set', 'conversationTags', tags)
  }
}

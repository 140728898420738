import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'tailwind-ui'

//@todo design
const badgeVariants = cva(
  'inline-flex items-center justify-center rounded-full border px-2 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-center cursor-default',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-green-200 text-green-800 hover:bg-green-200/80',
        secondary:
          'border-transparent bg-blue-200 text-blue-800 dark:bg-blue-800 dark:text-blue-200 hover:bg-blue-200/80',
        warn: 'border-transparent bg-yellow-400 text-yellow-800 hover:bg-yellow-200/80',
        destructive:
          'border-transparent bg-red-300 text-red-800 hover:bg-red-200/80',
        outblue:
          'border-blue-400/50 bg-blue-200/40 text-blue-800 dark:text-blue-100 hover:bg-blue-200/80',
        outpink:
          'border-stampede-400/50 bg-stampede-200/40 text-stampede-800 dark:text-stampede-100 hover:bg-stampede-200/80',
        subtle:
          'border-transparent bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300',
        outline: 'text-foreground',
      },
      //@todo sizes
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  action?: () => void
}

function Badge({
  className,
  variant,
  children,
  action = null,
  ...props
}: BadgeProps) {
  return (
    <span
      className={cn(
        badgeVariants({ variant }),
        {
          'pl-2': Boolean(action),
        },
        className
      )}
      {...props}
    >
      {children}
      {action ? (
        <button
          type="button"
          className="group relative rounded-full ml-1  h-3.5 w-3.5 hover:bg-gray-500/20 items-center justify-center flex"
          onClick={action}
        >
          <span className="sr-only">Remove</span>
          <svg
            viewBox="0 0 14 14"
            className="h-3.5 w-3.5 stroke-gray-600/50 dark:stroke-gray-400 dark:group-hover:stroke-gray-200 group-hover:stroke-gray-600/75"
          >
            <path d="M4 4l6 6m0-6l-6 6" />
          </svg>
          <span className="absolute -inset-1" />
        </button>
      ) : null}
    </span>
  )
}

export { Badge, badgeVariants }

import {
  ArrowDownOnSquareIcon,
  ArrowLeftIcon,
  ArrowLongUpIcon,
  ArrowsPointingInIcon,
  BookOpenIcon,
  ChartPieIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  CogIcon,
  ExclamationCircleIcon,
  GiftIcon,
  HomeIcon,
  InboxIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  PaperAirplaneIcon,
  ShareIcon,
  StarIcon,
  SwatchIcon,
  WalletIcon,
  WifiIcon,
  XMarkIcon,
  MapPinIcon,
} from '@heroicons/react/16/solid'
import {
  type Variants,
  motion,
  useAnimationControls,
  useDragControls,
} from 'framer-motion'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import {
  NavLink,
  type To,
  matchPath,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from 'react-router-dom'
import {
  Badge,
  BreadcrumbLink,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  SButton,
  SIconButton,
  SNavLink,
  Spinner,
  StampedeIcon,
  type SvgIcon,
  Tooltip,
  cx,
  invert,
} from 'tailwind-ui'
import { Modal } from 'tailwind-ui/src/modal'
import { throttle } from 'lodash'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { useLocalStorage } from 'usehooks-ts'
import { Alert, useAlertContext } from '@/components/alerts'
import useWindowWidth from '@/hooks/useWindowWidth'
import { ShareButton } from '@/providers/user-invite.context'
import inboxApi from '@/state/inbox/inbox.slice'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import {
  type UserConfigType,
  useUserConfig,
} from '@/state/morpheus/user-config/helpers'
import { getFirst } from '@/utils/common'
import usePrevious from '@/utils/usePrevious'
import useDebounce from '@/utils/helpers/debounce'
import type { OrganizationRegistration } from '@/state/morpheus/types/organization_registration'
import useSelectedOrganisation from '@/state/entities/organisations/hooks/useSelectedOrganisation'
import { useUserRole } from '@/hooks/useUser'
import useGlobal from '@/hooks/useGlobal'
import { usePathname } from '@/helpers/router'
import { defaultServicePagination } from '@/connect-types/backend/service'
import ProfileNav from '@/components/Layout/profile-nav'
import ProfileRow from '@/common/Table/ProfileRow'
import AppNotifications from '@/components/ProfilePage/AppNotifications'
import useBodyClass from '@/utils/helpers/pwa/useBodyClass'
import { useAppSelector } from '@/state/helpers/useApp'
import { useSelectedVenue } from '@/state/backend/venues/venues.hooks'
import { generateImage } from '@/helpers/image'
import { customerApi } from './customer/[id]/layout'
import VenuePage from './venues/index'

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[]
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed'
    platform: string
  }>
  prompt: () => Promise<void>
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent
  }
}

const menuItemVariant = (isVertical = true): Variants => ({
  open: {
    y: 0,

    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    visibility: 'hidden',
    ///  display: 'none',
    y: 50,
    margin: 0,
    ...(isVertical
      ? {
          height: 0,
        }
      : {
          width: 0,
        }),
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
})

type MainMenuKeyType =
  | 'venues'
  | 'bookings'
  | 'marketing'
  | 'inbox'
  | 'reviews'
  | 'loyalty'
  | 'gift-cards'
  | 'reports'
  | 'wi-fi'

const configToTooltipLabelPosition = (position: string) => {
  switch (position) {
    case 'top':
      return 'bottom'
    case 'bottom':
      return 'top'
    case 'left':
      return 'right'
    case 'right':
      return 'left'
    case '':
      return 'right'
  }
}

export const defaultButtonClasses =
  'border-0 bg-transparent text-white dark:text-black hover:bg-black dark:hover:bg-white group-[.active]:bg-black dark:group-[.active]:bg-black dark:focus:bg-gray-900  focus:bg-gray-50 focus:text-gray-900  dark:focus:text-gray-50'

function ActionBarItem({
  name = '',
  onClick,
  icon,
  to,
  id,
  count,
  isActive = false,

  className = '',
}: {
  name: string
  onClick?: () => void
  icon: SvgIcon
  id: string
  to?: To
  count?: number
  isActive?: boolean

  className: string
}) {
  const config = useUserConfig()
  const { pathname } = useLocation()

  const variants = useMemo(() => {
    return menuItemVariant(config.action_bar.vertical)
  }, [config.action_bar.vertical])

  const isMatch = useMemo(() => {
    const isVenuePath = pathname.includes('/venues')
    return matchPath(
      pathname
        .split('/')
        .slice(0, isVenuePath ? 5 : 3)
        .join('/'),
      to?.toString() ?? ''
    )
  }, [to, pathname])

  if (to) {
    return (
      <Tooltip
        side={configToTooltipLabelPosition(config.action_bar.position)}
        information={!config.action_bar.label && name}
      >
        <motion.div
          key={id}
          style={{ touchAction: 'none' }}
          variants={!isMatch ? variants : {}}
          className={cx(
            'relative  group group-[.dragging]:pointer-events-none',
            {
              active: Boolean(isMatch),
            }
          )}
        >
          {count && count > 0 ? (
            <Badge
              className="absolute border-0 -right-0.5  py-0 z-10 px-1 truncate min-w-[22px] bg-red-500 group-hover:bg-red-600 text-white  font-light"
              variant="secondary"
            >
              {count >= 99 ? '99+' : count}
            </Badge>
          ) : null}

          <NavLink to={to} draggable="false">
            <SButton
              size="lg"
              // isActive={!!isMatch || isActive}
              classNames={{
                span: cx(
                  config.action_bar.icon &&
                    config.action_bar.label &&
                    'justify-start',
                  'group-[.warning]:text-white '
                ),
              }}
              className={cx(
                'w-full  h-12 justify-center group-[.active]:bg-stampede-500 group-[.active]:text-white rounded-full',
                {
                  'px-2 w-12': !config.action_bar.label,
                  'px-6': config.action_bar.label,
                  // ['text-black dark:text-white']: !!isMatch,
                },
                defaultButtonClasses,
                className
              )}
              leftIcon={config.action_bar.icon ? icon : null}
              variant="ghost_default"
            >
              {config.action_bar.label ? name : null}
            </SButton>
          </NavLink>
        </motion.div>
      </Tooltip>
    )
  }

  return (
    <motion.div
      key={id}
      className={cx('relative w-full group-[.dragging]:pointer-events-none', {
        'active group': isActive,
      })}
      variants={pathname.includes(id) ? undefined : variants}
    >
      {count && count > 0 ? (
        <Badge
          className="absolute border-0 -right-0.5  py-0 z-10 px-1 truncate min-w-[22px] bg-red-500 group-hover:bg-red-600 text-white  font-light"
          variant="secondary"
        >
          {count >= 99 ? '99+' : count}
        </Badge>
      ) : null}
      <Tooltip
        side={configToTooltipLabelPosition(config.action_bar.position)}
        information={!config.action_bar.label && name}
      >
        <SButton
          {...(Boolean(onClick) && { onClick })}
          //  isActive={isActive}
          size="lg"
          classNames={{
            span: cx(
              config.action_bar.icon &&
                config.action_bar.label &&
                'justify-start',
              'group-[.warning]:!text-white'
            ),
          }}
          className={cx(
            'w-full  h-12 justify-center group-[.active]:bg-stampede-500 group-[.active]:text-white rounded-full',
            {
              'px-2 !w-12': !config.action_bar.label,
              'px-6': config.action_bar.label,
              // ['text-black dark:text-white']: !!isMatch,
            },
            defaultButtonClasses,
            className
          )}
          leftIcon={config.action_bar.icon ? icon : null}
          variant="ghost_default"
        >
          {config.action_bar.label ? name : null}
        </SButton>
      </Tooltip>
    </motion.div>
  )
}

function NavItems({
  toInclude = [
    'venues',
    'marketing',
    'inbox',
    'reviews',
    'loyalty',
    'gift-cards',
    'reports',
    'bookings',
    'wi-fi',
  ],
}: {
  toInclude?: Partial<MainMenuKeyType>[]
}) {
  // const size = useBreakpointValue({ base: 'md', lg: 'md' })
  const organisation = useSelectedOrganisation()
  const role = useUserRole()
  const { width } = useWindowWidth()
  const pathname = usePathname()
  const { serial } = useParams<{ serial: string }>()
  const config = useUserConfig()
  const { data: selectedVenue, isLoading } = useSelectedVenue(serial)
  const { data: unreadCount = { open: 0, closed: 0 } } =
    inboxApi.useGetUnreadCountQuery(
      {
        orgId: organisation?.id!,
        serial,
      },
      {
        skip: !organisation?.id,
      }
    )

  const toIncludeWithAuth = useMemo(() => {
    if (!role) return []
    switch (role?.key) {
      case 'marketeer':
        return toInclude.filter(
          (item) => !['gift-cards', 'venues', 'gift-cards'].includes(item)
        )
      case 'booking':
        return toInclude.filter(
          (item) => !['gift-cards', 'loyalty', 'marketing'].includes(item)
        )
      case 'reports':
        return toInclude.filter(
          (item) =>
            ![
              'marketing',
              'gift-cards',
              'inbox',
              'venues',
              'reviews',
              'loyalty',
            ].includes(item)
        )
      case 'moderator':
        return toInclude.filter(
          (item) =>
            !['marketing', 'gift-cards', 'reports', 'wi-fi'].includes(item)
        )
      case 'admin':
        return toInclude
      case 'super':
        return toInclude
      case 'reseller':
        return toInclude.filter(
          (item) =>
            ![
              'marketing',
              'gift-cards',
              'inbox',
              'reviews',
              'loyalty',
            ].includes(item)
        )
    }
  }, [toInclude, role])

  const [isVenuesOpen, setIsVenuesOpen] = useState(false)
  const [isVenueMenuOpen, setIsVenueMenuOpen] = useState(false)

  const isVenuePath = pathname.includes('venues')

  useLayoutEffect(() => {
    setIsVenuesOpen(false)
    setIsVenueMenuOpen(false)
  }, [pathname])

  const [shadowVenue, setShadowVenue] = useLocalStorage(
    'active-menu-venue',
    selectedVenue
  )

  useEffect(() => {
    if (!selectedVenue) return
    setShadowVenue(selectedVenue)
  }, [selectedVenue])

  if (!organisation || !role) return <></>

  return (
    <>
      <Modal
        size="full"
        classNames={{
          body: 'mx-auto',
        }}
        isOpen={isVenuesOpen}
        onClose={() => {
          setIsVenuesOpen(false)
        }}
      >
        <VenuePage
          onClose={() => {
            setIsVenuesOpen(false)
          }}
        />
      </Modal>
      {'serviceWorker' in navigator && <AppNotifications />}
      {toIncludeWithAuth.includes('venues') &&
        organisation?.locations.length > 1 && (
          <ActionBarItem
            name="Switch Venues"
            icon={MapPinIcon}
            id="venues"
            onClick={() => {
              setIsVenuesOpen(true)
            }}
            isActive={isVenuePath}
          />
        )}
      {shadowVenue && organisation?.locations.length > 1 ? (
        <DropdownMenu
          onOpenChange={setIsVenueMenuOpen}
          open={isVenueMenuOpen}
          modal
        >
          <DropdownMenuTrigger
            className={cx(
              'flex  md:min-w-full min-h-full relative items-center justify-center gap-1 overflow-hidden focus:outline-none',
              {
                'rounded-full min-w-[48px] min-h-[48px] h-[48px] w-[48px] max-w-[48px] max-h-[48px]':
                  config.action_bar.icon,
                'size-full': !config.action_bar.icon,
                'rounded-full': config.action_bar.label,
              }
            )}
            style={{
              backgroundColor:
                shadowVenue.branding_settings?.background_pallet['500'],
            }}
          >
            <Tooltip
              information={shadowVenue.alias}
              side={configToTooltipLabelPosition(config.action_bar.position)}
            >
              <NavLink
                to={`/${organisation.id}/venues/${serial}`}
                style={{
                  color: invert(shadowVenue.branding_settings.background, true),
                }}
                className={cx(
                  'size-full flex items-center justify-center relative',
                  {
                    'p-2.5 font-semibold': config.action_bar.label,
                  }
                )}
              >
                {config.action_bar.icon ? (
                  <>
                    <div
                      className="z-30 size-full flex items-center justify-center"
                      overflow-hidden
                      style={{
                        backgroundImage: `linear-gradient(to bottom, ${shadowVenue.branding_settings.background} 0%, transparent 95%)`,
                      }}
                    >
                      <p
                        className="text-2xl font-bold"
                        style={{
                          fontFamily:
                            shadowVenue.branding_settings?.font_family,
                        }}
                      >
                        {shadowVenue.alias?.charAt(0)}
                      </p>
                    </div>
                    <img
                      src={generateImage(
                        shadowVenue?.branding_settings?.backgroundImage,
                        'avatar'
                      )}
                      className="size-full object-cover absolute"
                    />
                  </>
                ) : (
                  shadowVenue?.alias ?? serial
                )}
              </NavLink>
            </Tooltip>
            <span className="sr-only">Toggle menu</span>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start" side="left">
            <DropdownMenuItem className="p-0 overflow-hidden">
              <NavLink
                className="size-full py-2 px-3 aria-[current=page]:text-white aria-[current=page]:bg-stampede-500"
                to={`/${organisation.id}/venues/${shadowVenue.serial}`}
                end
              >
                {shadowVenue?.alias ?? serial} Home
              </NavLink>
            </DropdownMenuItem>
            <DropdownMenuItem className="p-0 overflow-hidden">
              <NavLink
                className="size-full py-2 px-3 aria-[current=page]:text-white aria-[current=page]:bg-stampede-500"
                to={`/${organisation.id}/venues/${shadowVenue.serial}/inbox`}
              >
                Inbox
              </NavLink>
            </DropdownMenuItem>
            <DropdownMenuItem className="p-0 overflow-hidden">
              <NavLink
                className="size-full py-2 px-3 aria-[current=page]:text-white aria-[current=page]:bg-stampede-500"
                to={`/${organisation.id}/venues/${shadowVenue.serial}/bookings`}
              >
                Bookings
              </NavLink>
            </DropdownMenuItem>
            <DropdownMenuItem className="p-0 overflow-hidden">
              <NavLink
                className="size-full py-2 px-3 aria-[current=page]:text-white aria-[current=page]:bg-stampede-500"
                to={`/${organisation.id}/venues/${shadowVenue.serial}/reviews`}
              >
                Reviews
              </NavLink>
            </DropdownMenuItem>
            <DropdownMenuItem className="p-0 overflow-hidden">
              <NavLink
                className="size-full py-2 px-3 aria-[current=page]:text-white aria-[current=page]:bg-stampede-500"
                to={`/${organisation.id}/venues/${shadowVenue.serial}/loyalty`}
              >
                Loyalty
              </NavLink>
            </DropdownMenuItem>
            <DropdownMenuItem className="p-0 overflow-hidden">
              <NavLink
                className="size-full py-2 px-3 aria-[current=page]:text-white aria-[current=page]:bg-stampede-500"
                to={`/${organisation.id}/venues/${shadowVenue.serial}/brand`}
              >
                Brand Kit
              </NavLink>
            </DropdownMenuItem>
            <DropdownMenuItem className="p-0 overflow-hidden">
              <NavLink
                className="size-full py-2 px-3 aria-[current=page]:text-white aria-[current=page]:bg-stampede-500"
                to={`/${organisation.id}/venues/${shadowVenue.serial}/wi-fi`}
              >
                Wi-Fi
              </NavLink>
            </DropdownMenuItem>
            <DropdownMenuItem className="p-0 overflow-hidden">
              <NavLink
                className="size-full py-2 px-3 aria-[current=page]:text-white aria-[current=page]:bg-stampede-500"
                to={`/${organisation.id}/venues/${shadowVenue.serial}/reports`}
              >
                Reports
              </NavLink>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ) : null}
      {toIncludeWithAuth.includes('venues') &&
        organisation?.locations.length === 1 && (
          <ActionBarItem
            name="Venue"
            icon={MapPinIcon}
            id="venues"
            to={`/${organisation.id}/venues/${organisation.locations[0]?.serial}`}
            isActive={isVenuePath}
          />
        )}

      {toIncludeWithAuth.includes('inbox') && (
        <ActionBarItem
          name="Inbox"
          icon={InboxIcon}
          id="inbox"
          count={unreadCount.open}
          to={`/${organisation.id}/inbox`}
        />
      )}
      {toIncludeWithAuth.includes('marketing') ? (
        <ActionBarItem
          name="Marketing"
          icon={PaperAirplaneIcon}
          id="marketing"
          to={`/${organisation.id}/marketing`}
        />
      ) : null}

      {toIncludeWithAuth.includes('reviews') && (
        <ActionBarItem
          name="Reviews"
          icon={StarIcon}
          id="reviews"
          to={`/${organisation.id}/reviews`}
        />
      )}
      {toIncludeWithAuth.includes('loyalty') && (
        <ActionBarItem
          name="Loyalty"
          icon={WalletIcon}
          id="loyalty"
          to={`/${organisation.id}/loyalty`}
        />
      )}
      {!isVenuePath && toIncludeWithAuth.includes('gift-cards') && (
        <ActionBarItem
          name="Gifting"
          icon={GiftIcon}
          id="gifting"
          to={`/${organisation.id}/gifting`}
        />
      )}
      {width >= 768 && isVenuePath ? (
        <ActionBarItem
          name="Brand Kits"
          icon={SwatchIcon}
          id="brand"
          to="organisation/brand"
        />
      ) : null}

      {toIncludeWithAuth.includes('reports') && (
        <ActionBarItem
          name="Reports"
          icon={ChartPieIcon}
          id="reports"
          to="reports"
        />
      )}
    </>
  )
}

const dragConstraintsFromPosition = (
  position: string,
  { width, height }: { width: number; height: number },
  dimensions: { width: number; height: number }
) => {
  const actionHelperSize = 42
  const iconHeight = 66
  const { width: floatingWidth, height: floatingHeight } = dimensions

  switch (position) {
    case 'top':
      return {
        left: -(width / 2) + floatingWidth / 2,
        right: width / 2 - floatingWidth / 2,
        bottom: height - (actionHelperSize + iconHeight),
        top: 0 - actionHelperSize,
      }
    case 'bottom':
      return {
        left: -(width / 2) + floatingWidth / 2,
        right: width / 2 - floatingWidth / 2,
        top: -(height - (actionHelperSize + iconHeight)),
        bottom: 0 + actionHelperSize,
      }
    case 'left':
      return {
        right: width - floatingWidth * 2 + actionHelperSize * 3,
        top: -(height / 2) + floatingHeight / 2,
        bottom: height / 2 - floatingHeight / 2,
        left: 0 - actionHelperSize,
      }
    case 'right':
      return {
        left: -(width - floatingWidth * 2 + actionHelperSize * 2),
        top: -(height / 2) + floatingHeight / 2,
        bottom: height / 2 - floatingHeight / 2,
        right: 0 + actionHelperSize,
      }
  }
}

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[]
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed'
    platform: string
  }>
  prompt: () => Promise<void>
}

function useAddToHomescreenPrompt(): [
  IBeforeInstallPromptEvent | null,
  () => void,
] {
  const [prompt, setState] = useState<IBeforeInstallPromptEvent | null>(null)

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt()
    }
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    )
  }

  useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault()
      setState(e)
    }

    window.addEventListener('beforeinstallprompt', ready)

    return () => {
      window.removeEventListener('beforeinstallprompt', ready)
    }
  }, [])

  return [prompt, promptToInstall]
}

const getYFromPosition = (pos: string) => {
  switch (pos) {
    case 'bottom':
      return { x: 0, y: 40 }
    case 'top':
      return { x: 0, y: -40 }
    case 'left':
      return { x: -100, y: 0 }
    case 'right':
      return { x: 100, y: 0 }
  }
}

export function ActionBar() {
  const { orgId } = useGlobal()
  //const bg = useColorModeValue('white', 'gray.900')
  const { state } = useNavigation()
  const [term, setTerm] = useState('')
  const search = useDebounce(term, 600)
  const { pathname } = useLocation()
  const [searchVisible, setSearchVisible] = useState(false)
  const { serial, org_id } = useParams<{ serial: string; org_id: string }>()
  const organisation = useSelectedOrganisation()
  const [alerts] = useAlertContext()
  const [prompt, promptToInstall] = useAddToHomescreenPrompt()

  const {
    data = defaultServicePagination,
    isLoading,
    isFetching,
  } = customerApi.useGetCustomersQuery(
    {
      orgId,
      search,
      query: {
        limit: 5,
        cursor: '',
      },
    },
    {
      skip: !searchVisible,
    }
  )

  useEffect(() => {
    setSearchVisible(false)
  }, [pathname, setSearchVisible])

  const [isOpen, setIsOpen] = useState(true)
  const variants: Variants = {
    open: {
      transition: {
        staggerChildren: 0.07,
        delayChildren: 0.1,
      },
    },
    closed: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
        delayChildren: 0.1,
      },
    },
  }

  const controls = useDragControls()
  const animationControls = useAnimationControls()
  const [isEditOpen, setIsEditOpen] = useState(false)
  const config = useUserConfig()
  const navigate = useNavigate()
  const floatingRef = useRef<HTMLDivElement | null>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const [isDragging, setIsDragging] = useState(false)
  const [alertsIsOpen, setAlertsIsOpen] = useState(false)

  useLayoutEffect(() => {
    setAlertsIsOpen(false)
  }, [pathname])

  useEffect(() => {
    if (alerts.filter((item) => !item.isDismissed).length > 0) return
    setAlertsIsOpen(false)
  }, [alerts])

  useLayoutEffect(() => {
    if (!config.isSuccess) return

    setIsEditOpen(!config.action_bar.has_updated)
  }, [config.isSuccess, config.action_bar.has_updated])

  const { handleSubmit, register, reset, setValue, watch } =
    useForm<UserConfigType>({
      defaultValues: config,
    })

  useEffect(() => {
    reset(config)
  }, [config])

  const position = watch('action_bar.position')
  const label = watch('action_bar.label')
  const icons = watch('action_bar.icon')
  const isFixed = watch('action_bar.is_fixed')

  const { width, height } = useWindowWidth()
  const prevWidth = usePrevious(width)
  const [updateConfig, { isLoading: isConfigUpdateLoading }] =
    morpheusApi.useUpdateUserConfigMutation()

  useEffect(() => {
    if (!prevWidth) return
    if (!width) return
    if (prevWidth === width) return
    setTimeout(() => {
      animationControls.start({
        x: 0,
        y: 0,
      })
      setIsOpen(true)
    }, 1000)
  }, [width, prevWidth])

  useEffect(() => {
    if (!config) return
    setTimeout(() => {
      animationControls.start({
        x: 0,
        y: 0,
      })
    }, 1000)
  }, [config])

  useLayoutEffect(() => {
    if (config.action_bar.position !== 'left') return
    if (config.action_bar.is_fixed) return
    setTimeout(() => {
      if (document.body.classList.contains('page-has-side-nav')) {
        animationControls.start({
          x: (width > 1280 ? 440 : 320) - floatingRef.current.clientWidth - 12,
          y: 40,
          transition: {
            duration: 0.75,
          },
        })
      } else {
        animationControls.start({
          x: 0,
          y: 0,
          transition: {
            duration: 0.5,
          },
        })
      }
    }, 1250)
  }, [
    pathname,
    config.action_bar.position,
    config.action_bar.is_fixed,
    animationControls,
    width,
  ])

  useLayoutEffect(() => {
    const depth = pathname.split('/').length
    const isOpen = depth <= 3 && !pathname.includes('inbox')
    setTimeout(
      () => {
        // setIsOpen(isOpen)
      },
      !isOpen ? 10000 : 750
    )
  }, [pathname])

  const onSubmit: SubmitHandler<UserConfigType> = async (data) => {
    return toast.promise(
      updateConfig({
        ...data,
        action_bar: {
          ...data.action_bar,
          position: !data.action_bar.position
            ? 'left'
            : data.action_bar.position,
        },
      }).unwrap(),
      {
        loading: 'Saving...',
        success: () => {
          setIsOpen(true)
          setIsEditOpen(false)
          return 'Saved'
        },
        error: 'Failed to save',
      }
    )
  }

  const resizeObserver = useRef<ResizeObserver | null>(null)

  useEffect(() => {
    if (isDragging) return
    if (floatingRef.current) {
      resizeObserver.current = new ResizeObserver(
        throttle((entries) => {
          const outerPadding = 18
          const observedHeight =
            getFirst(entries).contentRect.height + outerPadding
          const ovservedWidth =
            getFirst(entries).contentRect.width + outerPadding
          setDimensions({ height: observedHeight, width: ovservedWidth })
        }, 1000)
      )

      resizeObserver.current.observe(floatingRef.current)

      return () => {
        // Cleanup the observer when the component is unmounted
        resizeObserver.current.disconnect()
      }
    }
  }, [isDragging])

  const push = useNavigate()

  const AlertsToIcon = useMemo(() => {
    //apply sorting
    const icons = alerts.map((item) => {
      switch (item.type) {
        case 'info':
          return InformationCircleIcon
        case 'success':
          return CheckCircleIcon
        case 'warning':
          return ExclamationCircleIcon
        case 'error':
          return XMarkIcon
      }
    })
    return getFirst(icons)
  }, [alerts])

  const AlertsToClassName = useMemo(() => {
    //apply sorting
    const icons = alerts.map((item) => {
      switch (item.type) {
        case 'info':
          return '!bg-blue-600'
        case 'success':
          return '!bg-green-600'
        case 'warning':
          return '!bg-orange-600'
        case 'error':
          return '!bg-red-600'
      }
    })
    return getFirst(icons)
  }, [alerts])

  return (
    <>
      <Modal
        title="Guest Search"
        isOpen={searchVisible}
        onClose={() => {
          setSearchVisible(false)
        }}
        classNames={{
          body: '!p-0 divide-y',
        }}
      >
        <div className="flex justify-between items-center relative">
          <input
            className="form-input bg-gray-50 dark:bg-gray-900 flex-1 w-full border-0 focus:ring-transparent"
            onChange={(event) => {
              setTerm(event.target.value)
            }}
            placeholder="Search by email or phone..."
            type="text"
          />

          {isLoading || isFetching ? (
            <Spinner className="mr-0.5 absolute right-2 " />
          ) : null}
        </div>
        <div className="rounded-b-xl">
          {(data.data as OrganizationRegistration[]).map(
            ({ id, user_profile: { email, first, last } }) => (
              <NavLink
                key={id}
                to={`customer/${id}`}
                className="flex justify-between items-center bg-white dark:bg-black hover:bg-gray-100 dark:hover:bg-gray-900 px-4 pr-2 py-2 border-b last:border-b-transparent"
              >
                <ProfileRow email={email} first={first} last={last} />

                <ChevronRightIcon className="mr-2 h-4 w-4" />
              </NavLink>
            )
          )}

          {data.data.length === 0 && !isFetching && !isLoading && term ? (
            <div className="p-4 flex items-end flex-col">
              <p className="text-sm text-gray-400 dark:text-gray-500">
                No results for {term}
              </p>
            </div>
          ) : null}
          {data.data.length === 0 && (isFetching || isLoading) ? (
            <div className="p-4 flex items-end flex-col">
              <p className="text-sm text-gray-400 dark:text-gray-500">
                Loading {!term ? `results for ${term}` : `initial results`}
              </p>
            </div>
          ) : null}
          {data.data.length !== 0 ? (
            <div className="p-4 flex justify-between items-center">
              <p className="text-sm text-gray-400 dark:text-gray-500">
                {data.data.length}
                {data.data.length >= 5 ? `+ ` : ` `}
                customers
              </p>
              {data.links.next ? (
                <SNavLink
                  rightIcon={ChevronRightIcon}
                  end
                  to="customer"
                  className="rounded-full"
                  size="sm"
                >
                  Show More
                </SNavLink>
              ) : null}
            </div>
          ) : null}
        </div>
      </Modal>
      <Modal
        title="Customise menu"
        isOpen={isEditOpen}
        onClose={() => {
          setIsEditOpen(false)
        }}
        size="xl"
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-3 max-lg:pb-24"
        >
          <div className="flex flex-col lg:flex-row gap-4">
            <div className="bg-green-100 lg:max-w-72	 dark:bg-green-800 border dark:border-green-900 border-green-200 rounded-xl p-4 flex space-y-4 flex-col">
              <CogIcon className="w-12 h-12  duration-1000 ease-linear animate-[wiggle_1s_ease-in-out_infinite]	 fill-green-500 dark:fill-green-100" />
              <div>
                <p className="text-green-900 dark:text-green-100 text-sm font-semibold">
                  Menu Update
                </p>
                <div className="space-y-3">
                  <p className="text-green-900 text-sm dark:text-green-100">
                    As Stampede's feature set is now being widely used by
                    customers who are taking advantage of our entire ecosystem
                    of products we had to create a more efficient way of
                    navigating around the platform.
                  </p>
                  <p className="text-green-900 text-sm dark:text-green-100">
                    You can now quickly flick through any product or feature by
                    using the action bar. This will allow you to quickly
                    navigate between venues, bookings, marketing, inbox,
                    reviews, loyalty, gift cards and reports.
                  </p>
                  <p className="text-green-900 text-sm dark:text-green-100">
                    This interface now works seamlessly across all devices.
                    Mobile, tablet and desktop.
                  </p>
                  <p className="text-green-900 text-sm dark:text-green-100">
                    You can currently customise the style and position of the
                    action bar, if it ever gets in the way try giving it a drag
                    🏎️.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col w-full space-y-4">
              <fieldset>
                <div className="bg-white dark:bg-black border rounded-xl p-4 flex flex-col space-y-4">
                  <legend className="text-sm font-semibold dark:text-gray-200 text-gray-900">
                    Menu Items
                  </legend>
                  <div className="gap-6 items-end justify-evenly flex ">
                    <div className="flex flex-col gap-4 items-center">
                      <SIconButton
                        className="border-0 bg-black/50 dark:bg-white/50 rounded-full text-white dark:text-black"
                        icon={HomeIcon}
                        size="lg"
                        isRound
                        onClick={() => {
                          setValue('action_bar.icon', true)
                          setValue('action_bar.label', false)
                        }}
                      />
                      <div
                        className={cx('h-4 w-4 rounded-full', {
                          'bg-green-500': icons,
                          'bg-yellow-500': label,
                        })}
                      />
                    </div>
                    <div className="flex flex-col gap-4 items-center">
                      <SButton
                        size="lg"
                        className="border-0 bg-black/50 dark:bg-white/50 rounded-full text-white dark:text-black"
                        variant="ghost_default"
                        onClick={() => {
                          setValue('action_bar.icon', false)
                          setValue('action_bar.label', true)
                        }}
                      >
                        Home
                      </SButton>
                      <div
                        className={cx('h-4 w-4 rounded-full', {
                          'bg-green-500': !icons,
                          'bg-yellow-500': !label,
                        })}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <div className="bg-white dark:bg-black border rounded-xl p-4 flex flex-col space-y-4">
                  <legend className="text-sm font-semibold dark:text-gray-200 text-gray-900">
                    Floating Menu
                  </legend>
                  <div className="gap-6 items-end justify-evenly flex ">
                    <div className="flex flex-col gap-4 items-center">
                      <SButton
                        className="border-0 bg-black/50 dark:bg-white/50 rounded-full text-white dark:text-black"
                        icon={HomeIcon}
                        size="lg"
                        isRound
                        onClick={() => {
                          setValue('action_bar.is_fixed', true)
                        }}
                      >
                        Fixed
                      </SButton>
                      <div
                        className={cx('h-4 w-4 rounded-full', {
                          'bg-green-500': isFixed,
                          'bg-yellow-500': !isFixed,
                        })}
                      />
                    </div>
                    <div className="flex flex-col gap-4 items-center">
                      <SButton
                        size="lg"
                        className="border-0 bg-black/50 dark:bg-white/50 rounded-full text-white dark:text-black"
                        variant="ghost_default"
                        onClick={() => {
                          setValue('action_bar.is_fixed', false)
                        }}
                      >
                        Floating
                      </SButton>
                      <div
                        className={cx('h-4 w-4 rounded-full', {
                          'bg-green-500': !isFixed,
                          'bg-yellow-500': isFixed,
                        })}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>

              <div className="flex flex-col justify-center gap-16 bg-white dark:bg-black rounded-xl border p-4">
                <div className="flex flex-col gap-2 justify-center">
                  <legend className="text-sm font-semibold dark:text-gray-200 text-gray-900">
                    Menu Position
                  </legend>
                  <SIconButton
                    size="lg"
                    onClick={() => {
                      setValue('action_bar.position', 'top')
                    }}
                    icon={ArrowLongUpIcon}
                    className="self-center"
                    isActive={position === 'top'}
                    isRound
                  />
                </div>

                <div>
                  <div className="flex justify-between items-center">
                    <SIconButton
                      size="lg"
                      onClick={() => {
                        setValue('action_bar.position', 'left')
                      }}
                      icon={ArrowLongUpIcon}
                      classNames={{
                        icon: '-rotate-90',
                      }}
                      isActive={position === 'left'}
                      isRound
                    />
                    <motion.div
                      className="relative"
                      animate={getYFromPosition(position)}
                      transition={{ ease: 'easeOut', duration: 1 }}
                    >
                      <StampedeIcon className="w-16 h-16 fill-black dark:fill-white" />
                    </motion.div>
                    <SIconButton
                      size="lg"
                      isRound
                      classNames={{
                        icon: 'rotate-90',
                      }}
                      onClick={() => {
                        setValue('action_bar.position', 'right')
                      }}
                      icon={ArrowLongUpIcon}
                      isActive={position === 'right'}
                    />
                  </div>
                </div>
                <SIconButton
                  size="lg"
                  classNames={{
                    icon: 'rotate-180',
                  }}
                  onClick={() => {
                    setValue('action_bar.position', 'bottom')
                  }}
                  icon={ArrowLongUpIcon}
                  className="self-center"
                  isActive={position === 'bottom'}
                  isRound
                />
              </div>

              <div className="flex justify-end pt-6">
                <SButton
                  variant="primary"
                  type="submit"
                  size="lg"
                  className="rounded-full border-0 px-6"
                  isLoading={isConfigUpdateLoading}
                >
                  Save
                </SButton>
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={alertsIsOpen}
        onClose={() => {
          setAlertsIsOpen(false)
        }}
      >
        <motion.div animate={isOpen ? 'open' : 'closed'}>
          {alerts.length >= 1 && (
            <motion.div
              variants={variants}
              className="flex flex-col gap-2 p-2 overflow-y-auto "
            >
              {(alerts ?? []).map((alert) => (
                <Alert key={alert.itemKey} {...alert} />
              ))}
            </motion.div>
          )}
        </motion.div>
      </Modal>
      <motion.div
        animate={isOpen ? 'open' : 'closed'}
        className={cx('flex-1   flex', {
          'bottom-0 right-1/2 translate-x-[50%]':
            config.action_bar.position === 'bottom',
          'top-0 right-1/2 translate-x-[50%]':
            config.action_bar.position === 'top',
          'left-0 top-1/2 translate-y-[-50%]':
            config.action_bar.position === 'left',
          'right-0 top-1/2 translate-y-[-50%]':
            config.action_bar.position === 'right',
          'flex-col-reverse':
            !config.action_bar.vertical &&
            config.action_bar.position === 'bottom',

          'flex-row-reverse':
            config.action_bar.vertical &&
            config.action_bar.position === 'right',
          'flex-col': config.action_bar.position === 'top',
          'z-[99] fixed': isEditOpen,
          'z-50 absolute ': !isEditOpen && !config.action_bar.is_fixed,
          'flex-col-reverse justify-between min-h-full':
            config.action_bar.is_fixed &&
            ['left', 'right'].includes(config.action_bar.position),
          'items-center': !config.action_bar.is_fixed,
          '!translate-y-0 !translate-x-0': config.action_bar.is_fixed,
        })}
      >
        <div
          className={cx(
            'flex gap-1 items-center  backdrop-blur-lg justify-center bg-white/75 dark:bg-black/75',
            {
              'flex-col': config.action_bar.vertical,
              'rounded-full ': !config.action_bar.is_fixed,
              'py-4': config.action_bar.is_fixed && config.action_bar.vertical,
            }
          )}
        >
          <Tooltip
            information={`${organisation?.name} Home`}
            side={configToTooltipLabelPosition(config.action_bar.position)}
          >
            <SIconButton
              size="sm"
              variant="ghost_default"
              className="border-0 text-black dark:text-white"
              isRound
              icon={HomeIcon}
              isActive={pathname === `/${orgId}`}
              onClick={() => {
                navigate('')
              }}
            />
          </Tooltip>
          {isOpen ? (
            <>
              {width > 1023 ? (
                <Tooltip
                  information="Edit action bar"
                  side={configToTooltipLabelPosition(
                    config.action_bar.position
                  )}
                >
                  <SIconButton
                    size="sm"
                    variant="ghost_default"
                    isRound
                    icon={CogIcon}
                    isActive={isEditOpen}
                    onClick={(ev) => {
                      setIsEditOpen((s) => !s)
                      animationControls.start({
                        x: 0,
                        y: 0,
                      })
                    }}
                  />
                </Tooltip>
              ) : (
                <SIconButton
                  size="sm"
                  variant="ghost_default"
                  isRound
                  icon={ArrowsPointingInIcon}
                  onClick={() => {
                    animationControls.start({
                      x: 0,
                      y: 0,
                    })
                  }}
                />
              )}
            </>
          ) : null}
          <SIconButton
            size="sm"
            variant="ghost_default"
            className={cx('relative bg-gray-100 dark:bg-gray-800 border', {
              'rotate-45': !isOpen,
            })}
            isRound
            icon={XMarkIcon}
            classNames={{
              icon: !isOpen ? 'animate-pulse text-stampede-500' : '',
            }}
            onClick={() => {
              setIsOpen((s) => !s)
            }}
            isLoading={state === 'loading'}
          />
          {isOpen ? (
            <>
              <Tooltip
                information="Search customers"
                side={configToTooltipLabelPosition(config.action_bar.position)}
              >
                <SIconButton
                  size="sm"
                  aria-label="search customers"
                  icon={MagnifyingGlassIcon}
                  onClick={() => {
                    setSearchVisible(true)
                  }}
                  variant="ghost_default"
                  isRound
                />
              </Tooltip>
              <ShareButton
                size="sm"
                className="border-0 shadow-lg "
                isRound
                variant="ghost_default"
                icon={ShareIcon}
              />
            </>
          ) : null}
          {pathname !== `/${orgId}` && !isOpen && (
            <SIconButton
              size="sm"
              aria-label="go back"
              icon={ArrowLeftIcon}
              onClick={() => {
                push(-1)
              }}
              variant="ghost_default"
              isRound
            />
          )}
        </div>
        <motion.div
          className={cx('flex items-center justify-center  space-y-2 z-50', {
            'bottom-10 ': config.action_bar.position === 'bottom',
            'top-10 ': config.action_bar.position === 'top',
            'left-10 flex-col': config.action_bar.position === 'left',
            'right-10 flex-col': config.action_bar.position === 'right',
            'sticky lg:absolute': !config.action_bar.is_fixed,
          })}
          animate={animationControls}
          drag={!config.action_bar.is_fixed}
          ref={floatingRef}
          style={{
            touchAction: 'none',
            //pointerEvents: isOpen ? 'auto' : 'none',
          }}
          dragConstraints={dragConstraintsFromPosition(
            config.action_bar.position,
            { width, height },
            dimensions
          )}
          dragTransition={{ bounceStiffness: 500, bounceDamping: 10 }}
          dragElastic={0.15}
          dragControls={controls}
          onDragStart={() => {
            setIsDragging(true)
          }}
          onDragEnd={() => {
            setIsDragging(false)
          }}
        >
          <motion.div
            onAnimationComplete={() => {}}
            variants={variants}
            animate={isOpen ? 'open' : 'closed'}
            className={cx('my-auto flex items-center', {
              'p-2': config.action_bar.vertical,
              'p-1': !config.action_bar.vertical,
              'flex-col space-y-1': config.action_bar.vertical,
              'space-x-1': !config.action_bar.vertical,
              'rounded-full':
                (config.action_bar.icon && !config.action_bar.label) ||
                !config.action_bar.vertical,
              'rounded-3xl':
                config.action_bar.label && config.action_bar.vertical,
              'z-auto': isEditOpen,
              'dragging group': isDragging,
              'hover:cursor-move bg-black/50 dark:bg-white/50 backdrop-blur-lg border':
                !config.action_bar.is_fixed,
            })}
          >
            {width > 768 && (
              <motion.div
                className={cx({
                  active: isOpen,
                })}
                variants={
                  {
                    // open: menuItemVariant(config.action_bar.vertical).closed,
                    // closed: menuItemVariant(config.action_bar.vertical).open,
                  }
                }
                //framer needs a unique key to animate updates
                key={JSON.stringify({ clo: config.action_bar.vertical })}
              >
                <SIconButton
                  icon={ChevronUpIcon}
                  onClick={() => {
                    setIsOpen((s) => !s)
                  }}
                  //isActive={isOpen}
                  isRound
                  className={cx(
                    'transform-gpu transition-all	duration-500 focus:bg-transparent',
                    {
                      '-rotate-90': !config.action_bar.vertical,
                      'rotate-180': isOpen && config.action_bar.vertical,
                      'rotate-90': isOpen && !config.action_bar.vertical,
                      hidden: config.action_bar.is_fixed,
                    },
                    defaultButtonClasses
                  )}
                  variant="ghost_default"
                  classNames={{
                    icon: !isOpen && 'animate-bounce	',
                  }}
                />
              </motion.div>
            )}
            {alerts.filter((item) => !item.isDismissed).length >= 1 && (
              <Tooltip
                title="Account Warning"
                side={configToTooltipLabelPosition(config.action_bar.position)}
              >
                <SIconButton
                  icon={AlertsToIcon}
                  onClick={async () => {
                    setAlertsIsOpen(true)
                  }}
                  isRound
                  size="lg"
                  isActive={alertsIsOpen}
                  className={cx(
                    '!border-0 w-12 h-12  text-white',
                    AlertsToClassName
                  )}
                />
              </Tooltip>
            )}

            <NavItems key="navitems" />

            {prompt ? (
              <ActionBarItem
                icon={ArrowDownOnSquareIcon}
                name="Install"
                onClick={promptToInstall}
              />
            ) : null}
            <div
              className={cx({
                '-order-1': config.action_bar.is_fixed,
              })}
            >
              <ProfileNav key="profileitems" />
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  )
}

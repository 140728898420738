'use client'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { cn } from 'tailwind-ui'

export interface TooltipProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof tooltipVariants> {
  information?: string
  side?: 'top' | 'bottom' | 'left' | 'right'
}

export const tooltipVariants = cva(
  [
    // 'invisible group-hover:visible opacity-0 group-hover:opacity-100 transition',
    // 'absolute z-10 -translate-x-2/4 left-1/2',
    // 'py-2 px-2.5',
    // 'bg-white text-sm text-black whitespace-nowrap',
    // 'dark:bg-gray-700 dark:text-white',
    // 'rounded-md shadow-md',
  ]
  // {
  //   variants: {
  //     placement: {
  //       above: 'bottom-full mb-1',
  //       below: 'top-full mt-2',
  //     },
  //   },
  //   defaultVariants: {
  //     placement: 'below',
  //   },
  // }
)

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 2, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'rounded-full bg-gray-50/95 dark:bg-gray-900/95 backdrop-blur-xl border',
      'z-50 overflow-hidden px-3 py-1.5 text-sm shadow-md fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export function Tooltip({
  information,
  children,
  className,
  side = 'bottom',
}: TooltipProps) {
  if (!information) return children
  return (
    <TooltipPrimitive.Provider delayDuration={0} skipDelayDuration={500}>
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipContent
            sideOffset={4}
            side={side}
            className={cn(tooltipVariants({ className }))}
          >
            {information}
          </TooltipContent>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}

// export function Tooltip({
//   children,
//   tooltip,
//   placement,
//   className,
// }: TooltipProps) {
//   const tooltipRef = useRef<HTMLSpanElement>(null)
//   const container = useRef<HTMLDivElement>(null)

//   if (!tooltip) {
//     return children
//   }

//   return (
//     <div
//       className="group relative"
//       onMouseEnter={({ clientX: _x }) => {
//         if (!tooltipRef.current || !container.current) return
//         //const { left } = container.current.getBoundingClientRect()
//        // tooltipRef.current.style.left = `${clientX - left}px`
//       }}
//       ref={container}
//     >
//       {children}
//       {tooltip ? (
//         <span
//           className={cn(tooltipVariants({ placement, className }))}
//           ref={tooltipRef}
//         >
//           {tooltip}
//         </span>
//       ) : null}
//     </div>
//   )
// }

import type {
  BaseQueryFn,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { toast } from 'sonner'
import type { AxiosError, AxiosRequestConfig } from 'axios'
import { retry, createApi } from '@reduxjs/toolkit/query/react'
import cloneDeep from 'lodash/cloneDeep'
import { type GiftSettingsType, type GiftCardInstanceType } from 'types/gifting'
import { isEqual } from 'lodash'
import type {
  GiftActivationMutation,
  GiftActivationType,
} from '@/connect-types/gifting/gifting.types'
import type {
  ServiceCursorPaginatedResponseType,
  ServicePaginatedResponseType,
} from '@/connect-types/backend/service'
import type {
  Segment,
  SegmentPreviewTotalQueryType,
  SegmentType,
} from '@/state/entities/segment/segment.types'
import type { PaypalAccountType } from '@/pages/[org_id]/venues/[serial]/wi-fi/settings/paypal'
import type { InteractionRequestType } from '@/state/entities/interactions/interactions.types'
import config from '@/config'
import { ServiceErrorHandler } from '@/connect-types/backend/service'
import type {
  OrganisationSettings,
  OrganisationType,
} from '@/connect-types/organisations/organisations.type'
import type {
  PagenationRequestType,
  PaginationRepsonse,
} from '@/connect-types/pagination/pagination.type'
import type {
  CsvUploadRequestType,
  CsvUploadResponseType,
  CsvUploadsFromDynamo,
  SignedCsvUrlRequestType,
  SignedCsvUrlResponseType,
} from '@/connect-types/sources/csv.type'
import type { User } from '@/connect-types/user/user.type'
import type {
  OrganisationUsersAccess,
  UserRoleType,
} from '@/state/auth/auth.types'
import { customAxios, isAxiosError } from '@/utils/axiosHelper'
import { buildUrl } from '@/utils/common'
import { allSegment } from '@/state/entities/segment/segment.mock'
import type { GiftReportResponse } from '@/connect-types/analytics/giftingAnalytics.type'
import { type CandidateProfileType } from '@/components/EndUserProfilePage/Candidate'
import { secondsEpoch } from '@/pages/[org_id]/gifting/reports'
import type { LoyaltyDataPaginationType } from '../entities/loyalty/loyalty.types'
import { shouldBailout } from '../helpers'
import { type MappedProfileItem } from '../entities/reports/reports.type'
import { isHydrateAction } from '../store'
import type {
  OrganizationRegistrationTransactionTotalsType,
  OrganizationRegistrationTransactionType,
} from './types/organization_registration/transactions.types'
import type { SegmentProfileType } from './types/segments/customers'
import type { SimpleVenueType, VenueSettingsType } from './types/venue'
import type {
  WebsiteProfileCookie,
  WebsiteType,
  WebstieEventType,
} from './types/websites/index.types'
import type { LokeIntegrationConfig } from './types/integreations/loke'
import type { InteractionType } from './types/interaction'
import type {
  OrganizationType,
  SimpleOrganisationType,
} from './types/organisation'
import { type UserConfigType } from './user-config/helpers'

const parseRoot = (value: Partial<Segment>) => {
  const sendNode: any = cloneDeep(value)
  if (!sendNode.segment.root) {
    return sendNode
  }
  if (
    Array.isArray(sendNode.segment.root.nodes) &&
    sendNode.segment.root.nodes.length === 1
  ) {
    sendNode.segment.root = sendNode.segment.root.nodes[0]
  }

  if (
    Array.isArray(sendNode.segment.root.nodes) &&
    sendNode.segment.root.nodes.length === 0
  ) {
    sendNode.segment.root = null
  }
  return sendNode
}

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<{
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
  }> =>
  async ({ url, method, data, params }) => {
    try {
      let axiosUrl = baseUrl + url
      if (url.startsWith('https://')) {
        axiosUrl = url
      }

      const result = await customAxios({
        url: axiosUrl,
        method,
        data,
        params,
      })

      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError

      if (
        method !== 'get' &&
        err.response?.status !== 401 &&
        err.response?.status !== 404 &&
        !url.includes('segment')
      ) {
        ServiceErrorHandler(err)
      }

      if (shouldBailout(err)) {
        retry.fail(err)
      }

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

export const convertKeysToSnake = (item: Record<string, unknown>) => {
  const newObject = {}
  function camelToUnderscore(key: string) {
    return key.replace(/([A-Z])/g, '_$1').toLowerCase()
  }
  for (const camel in item) {
    newObject[camelToUnderscore(camel)] = item[camel]
  }
  return newObject
}

const morpheusApi = createApi({
  reducerPath: 'morpheus',
  baseQuery: retry(axiosBaseQuery({ baseUrl: config.url.morpheus }), {
    maxRetries: 3,
  }),

  refetchOnReconnect: true,
  tagTypes: [
    'Subscription',
    'Organisation',
    'User',
    'OrganisationSettings',
    'Venue',
    'Websites',
    'MarketingMenus',
    'Csv',
    'Segments',
    'CustomQuestions',
    'CustomQuestionEntities',
    'Forms',
    'Brand',
    'PayPal',
    'UserVenues',
    'Gifting',
    'GiftCardsActivations',
    'UserConfig',
  ],
  endpoints: (build) => ({
    /**
     * Segments start
     */
    createSingleOrganisation: build.mutation<
      OrganizationType,
      { data: { name: string; website: string } }
    >({
      query: ({ data }) => ({
        url: `/organisations`,
        method: 'POST',
        data,
      }),
    }),

    invite: build.mutation<{ id: string }, { orgId: string; data: object }>({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/users/invite`,
        method: 'POST',
        data,
      }),
    }),
    getReach: build.query<
      { all: number; email: number; sms: number },
      { orgId: string }
    >({
      query: ({ orgId }) => ({
        method: 'GET',
        url: `/organisations/${orgId}/crm/segments/all/data/reach`,
      }),
    }),
    getSegments: build.query<Segment[], { orgId: string }>({
      async queryFn({ orgId }, _queryApi, _extra, fetcher) {
        const segments = await fetcher({
          method: 'get',
          url: `/organisations/${orgId}/crm/segments`,
        })

        const allReach = await fetcher({
          method: 'get',
          url: `/organisations/${orgId}/crm/segments/all/data/reach`,
        })

        const data = (segments.data as Segment[]).map((item) => ({
          ...item,
          segment: {
            ...item.segment,
            serials: item.segment?.serials ? item.segment.serials : [],
          },
        })) as Segment[]

        if (data && allReach.data) {
          data.sort((a, b) => b.reach.reach.all - a.reach.reach.all)

          data.unshift({
            ...allSegment,

            reach: {
              reach: allReach.data as Segment['reach']['reach'],
              version: 0,
            },
          })
        }

        return segments.data
          ? {
              data,
            }
          : { error: segments.error as FetchBaseQueryError }
      },
      providesTags: (items) => [
        { type: 'Segments', id: 'LIST' },
        ...items.map((item) => ({ type: 'Segments' as const, id: item.id })),
      ],
    }),
    getSegment: build.query<Segment, { orgId: string; id: string }>({
      query: ({ orgId, id }) => ({
        url: `/organisations/${orgId}/crm/segments/${id}`,
        method: 'GET',
      }),
      providesTags: (data) =>
        data?.id ? [{ type: 'Segments', id: data.id }] : [],
    }),
    getSegmentPreview: build.query<
      ServicePaginatedResponseType<SegmentProfileType>,
      {
        orgId: string
        query: SegmentPreviewTotalQueryType
        cursor: { limit: number; cursor: string }
      }
    >({
      query: ({ orgId, query, cursor }) => ({
        url: buildUrl(`/organisations/${orgId}/crm/segments/preview`, cursor),
        method: 'POST',
        data: query,
      }),
    }),
    createSegment: build.mutation<
      Segment,
      {
        orgId: string
        segment: Partial<Segment>
      }
    >({
      query: ({ orgId, segment }) => {
        const sendNode: any = parseRoot(segment)
        if (!sendNode.segment.is_venue_segment) {
          sendNode.segment.serials = []
        }
        return {
          method: 'post',
          url: `/organisations/${orgId}/crm/segments`,
          data: sendNode,
        }
      },
      invalidatesTags: () => [{ type: 'Segments', id: 'LIST' }],
      transformResponse: (item: Segment) => {
        toast.success(`${item.name} created`)
        return item
      },
    }),
    deleteSegment: build.mutation<Segment, Segment>({
      query: (segment) => ({
        method: 'DELETE',
        url: `/organisations/${segment.organization_id}/crm/segments/${segment.id}`,
      }),
      invalidatesTags: () => [{ type: 'Segments', id: 'LIST' }],
      transformResponse: (item: Segment) => {
        toast.success(`${item.name} deleted`)
        return item
      },
    }),
    updateSegment: build.mutation<
      Segment,
      {
        orgId: string
        segment: Segment
      }
    >({
      query: ({ orgId, segment }) => {
        const sendNode: any = parseRoot(segment)
        if (!sendNode.segment.is_venue_segment) {
          sendNode.segment.serials = []
        }

        return {
          method: 'put',
          url: `/organisations/${orgId}/crm/segments/${segment.id}`,
          data: sendNode,
        }
      },
      invalidatesTags: (item) =>
        item?.id ? [{ type: 'Segments', id: item.id }] : [],
      transformResponse: (item: Segment) => {
        toast.success(`${item.name} updated`)
        return item
      },
    }),
    getSegmentPreviewReach: build.query<
      { all: number; sms: number; email: number },
      {
        orgId: string
        query: SegmentPreviewTotalQueryType
      }
    >({
      query: ({ orgId, query }) => ({
        url: `/organisations/${orgId}/crm/segments/preview/reach`,
        method: 'POST',
        data: query,
      }),
    }),
    getSegmentMetadata: build.query<
      SegmentType,
      {
        orgId: string
      }
    >({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/crm/segments/metadata`,
        method: 'GET',
      }),
      transformResponse(res: SegmentType) {
        const fieldsArray = Object.entries(res.fields).map(
          ([_key, value]) => value
        )

        return { ...res, fieldsArray }
      },
    }),
    /**
     * Segments end
     */

    updateSubscriptionStatus: build.mutation<
      string,
      { orgId: string; subscriptionId: string; status: string }
    >({
      query: ({ orgId, subscriptionId, status }) => ({
        url: `/organisations/${orgId}/billing/${subscriptionId}/status`,
        data: { status },
        method: 'put',
      }),

      invalidatesTags: ['Subscription'],
    }),
    getOrganisationsSearch: build.query<
      {
        data: {
          id: string
          name: string
          parent_organization_id: string
        }[]
      },
      { limit: number; search?: string }
    >({
      query: ({ limit, search }) => ({
        url: buildUrl(`/organisations/search`, { limit, q: search }),
        method: 'get',
      }),
    }),
    getOrganisations: build.query<
      ServiceCursorPaginatedResponseType<SimpleOrganisationType>,
      { cursor: string; limit: number; search?: string }
    >({
      query: ({ limit, search, cursor }) => ({
        url: buildUrl(`/organisations`, { cursor, limit, search }),
        method: 'get',
      }),
    }),
    getLocations: build.query<
      ServiceCursorPaginatedResponseType<SimpleVenueType>,
      { cursor: string; limit: number; search?: string }
    >({
      query: ({ limit, search, cursor }) => ({
        url: buildUrl(`/venues`, { cursor, limit, search }),
        method: 'get',
      }),
    }),
    /**
     *  organisations
     */
    getOrganisation: build.query<OrganisationType, string>({
      keepUnusedDataFor: 600,
      query: (orgId) => ({ url: `/organisations/${orgId}`, method: 'get' }),
      transformResponse(data: OrganisationType) {
        data.children = []

        return data
      },
      providesTags: (result) => [{ type: 'Organisation', id: result?.id }],
    }),
    updateOrganisation: build.mutation<
      OrganisationType,
      { orgId: string; data: { website: string; name: string } }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}`,
        method: 'put',
        data,
      }),

      invalidatesTags: (result) => [{ type: 'Organisation', id: result.id }],
    }),
    getOrganisationSettings: build.query<OrganisationSettings, string>({
      query: (orgId) => ({
        url: `/organisations/${orgId}/settings`,
        method: 'get',
      }),
      providesTags: ['OrganisationSettings'],
    }),
    updateOrganisationSettings: build.mutation<
      OrganisationSettings,
      { orgId: string; data: OrganisationSettings['settings'] }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/settings`,
        method: 'put',
        data,
      }),
      async onQueryStarted({ orgId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            morpheusApi.util.updateQueryData(
              'getOrganisationSettings',
              orgId,
              () => data
            )
          )
          toast.success('Settings updated')
        } catch {
          toast.error('Something went wrong, could not update settings')
        }
      },
    }),
    getOrganisationChildren: build.query<OrganisationType[], string>({
      query: (orgId) => ({
        url: `/organisations/${orgId}/children`,
        method: 'get',
      }),
      transformResponse(items: OrganisationType[]) {
        return items.map((item) => ({
          ...item,
          children: item.other_organization,
        }))
      },
      providesTags: (results) => {
        if (!results) return
        return [
          ...(results ?? []).map((item) => ({
            type: 'Organisation' as const,
            id: item.id,
          })),
          { type: 'Organisation', id: 'LIST' },
        ]
      },
    }),
    updateOrganisationLocations: build.mutation<
      OrganisationType,
      { orgId: string; serials: string[]; originalOrgId: string }
    >({
      query: ({ orgId, serials, originalOrgId }) => ({
        url: `/organisations/${orgId}/children`,
        method: 'put',
        data: { serials, originalOrgId },
      }),
      transformResponse(data: OrganisationType) {
        toast.success(`Venue(s) moved`)
        return data
      },
      invalidatesTags: () => [{ type: 'Organisation', id: 'LIST' }],
    }),
    createOrganisation: build.mutation<
      OrganisationType,
      { orgId: string; name: string }
    >({
      query: ({ orgId, name }) => ({
        url: `/organisations/${orgId}/children`,
        method: 'POST',
        data: { name },
      }),
      transformResponse(data: OrganisationType) {
        toast.success(`${data.name} created`)
        return data
      },
      invalidatesTags: [{ type: 'Organisation', id: 'LIST' }],
    }),

    /**
     * End organisations
     */

    getUser: build.query<User, string>({
      keepUnusedDataFor: 0,

      query: (_uid) => ({
        url: `/members`,
        method: 'get',
      }),

      providesTags: (_uid) => [{ type: 'User' as const, id: _uid?.uid }],
    }),
    updateUser: build.mutation<User, Partial<User>>({
      query: (data) => ({
        url: `/members/me`,
        method: 'put',
        data,
      }),
      invalidatesTags: (data) => [
        { type: 'User' as const, id: data?.uid ?? '' },
      ],
      transformResponse({
        message,
        updated_user,
      }: {
        message: string
        updated_user: User
      }) {
        toast.success(message)
        return updated_user
      },
    }),
    getUserConfig: build.query<UserConfigType, null>({
      keepUnusedDataFor: 0,
      query: () => ({
        url: `/members/config`,
        method: 'get',
      }),

      providesTags: [{ type: 'UserConfig', id: 'LIST' }],
    }),
    updateUserConfig: build.mutation<UserConfigType, Partial<UserConfigType>>({
      query: (data) => ({
        url: `/members/config`,
        method: 'put',
        data,
      }),
      invalidatesTags: [{ type: 'UserConfig', id: 'LIST' }],
    }),
    getOrganisationUserRole: build.query<
      UserRoleType,
      { orgId: string; uid: string }
    >({
      query: ({ orgId, uid }) => ({
        url: `/organisations/${orgId}/users/${uid}/role`,
        method: 'get',
      }),
    }),
    updateOrganisationUserRole: build.mutation<
      UserRoleType,
      { orgId: string; uid: string; role: string }
    >({
      query: ({ orgId, uid, role }) => ({
        url: `/organisations/${orgId}/users/${uid}/role`,
        method: 'put',
        data: { role },
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    removeOrganisationUser: build.mutation<
      UserRoleType,
      { orgId: string; uid: string; role: string }
    >({
      query: ({ orgId, uid }) => ({
        url: `/organisations/${orgId}/users/${uid}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    getOrganisationUser: build.query<User, { orgId: string; uid: string }>({
      query: ({ orgId, uid }) => ({
        url: `/organisations/${orgId}/users/${uid}`,
        method: 'get',
      }),
    }),
    getOrganisationUsers: build.query<
      OrganisationUsersAccess[],
      { orgId: string }
    >({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/users`,
        method: 'get',
      }),
      providesTags: [{ type: 'User', id: 'LIST' }],
    }),
    createOrganisationUsers: build.mutation<
      OrganisationUsersAccess,
      {
        orgId: string
        data: {
          email: string
          first?: string
          last?: string
          company?: string
          password?: string
        }
      }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/users`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    getOrganisationUserVenues: build.query<
      unknown[],
      { orgId: string; uid: string }
    >({
      query: ({ orgId, uid }) => ({
        url: `/organisations/${orgId}/users/${uid}/venues`,
        method: 'get',
      }),
      providesTags: [{ type: 'UserVenues', id: 'LIST' }],
    }),
    updateOrganisationUserVenues: build.mutation<
      VenueSettingsType[],
      { orgId: string; uid: string; access: string[] }
    >({
      query: ({ orgId, uid, access }) => ({
        url: `/organisations/${orgId}/users/${uid}/venues`,
        method: 'put',
        data: { access },
      }),
      invalidatesTags: [{ type: 'UserVenues', id: 'LIST' }],
    }),
    getHubspotAuthToken: build.query<{ token: string }, unknown>({
      query: () => ({
        url: `/oauth/hubspot-visitor-identification`,
        method: 'get',
      }),
    }),
    getZendeskToken: build.query<{ token: string }, unknown>({
      query: () => ({
        url: `/oauth/zendesk-identification`,
        method: 'get',
      }),
    }),
    updateOauthUserPassword: build.mutation<
      { message: string },
      { password: string }
    >({
      query: (input) => ({
        url: `/oauth/update-password`,
        method: 'PUT',
        data: { password: input.password },
      }),
    }),

    /**
     * Venue
     */
    getOperatingTypes: build.query<
      { id: string; name: string; sic_code: string }[],
      { orgId: string }
    >({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/venues/vertical-types`,
        method: 'get',
      }),
    }),
    getVenues: build.query<
      ServicePaginatedResponseType<SimpleVenueType>,
      { orgId: string; show_child_organisations: boolean }
    >({
      query: ({ orgId, show_child_organisations: lookChildren }) => ({
        url: buildUrl(`/organisations/${orgId}/venues`, {
          lookChildren,
          limit: 100,
        }),
        method: 'get',
      }),
    }),
    addVenue: build.mutation<VenueSettingsType, { orgId: string }>({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/venues`,
        method: 'post',
      }),
      invalidatesTags: (_, _1, { orgId }) => [
        { type: 'Organisation', id: orgId },
      ],
      transformResponse(data: VenueSettingsType) {
        toast.success(`Venue added`)
        return data
      },
    }),
    updateBrandLocation: build.mutation<
      unknown,
      { orgId: string; serial: string; branding_id: string }
    >({
      query: ({ serial, orgId, branding_id }) => ({
        url: `/organisations/${orgId}/venues/${serial}/brand`,
        method: 'PUT',
        data: {
          branding_id,
        },
      }),
      invalidatesTags: (_, _1, { serial, branding_id }) => [
        { type: 'Venue', id: serial },
        { type: 'Brand', id: branding_id },
      ],
      transformResponse(data) {
        return data
      },
    }),
    getLocation: build.query<
      VenueSettingsType,
      { orgId: string; serial: string }
    >({
      query: ({ serial, orgId }) => ({
        url: `/organisations/${orgId}/venues/${serial}`,
        method: 'GET',
      }),
      transformResponse(data: VenueSettingsType) {
        if (!data.paymentType) {
          data.paymentType = ''
        }
        return data
      },
      providesTags: (data, _error, { serial, orgId }) => {
        if (!data) return []
        const { custom_question_entries, id } = data

        return [
          { type: 'Organisation', id: orgId },
          { type: 'Venue', id: serial },
          { type: 'Venue', id },
          ...custom_question_entries.map((item) => ({
            type: 'CustomQuestionEntities' as const,
            id: item.id,
          })),
          ...custom_question_entries.map((item) => ({
            type: 'CustomQuestions' as const,
            id: item.custom_question_id,
          })),
        ]
      },
    }),
    updateLocation: build.mutation<
      VenueSettingsType,
      { orgId: string; serial: string; venue: Partial<VenueSettingsType> }
    >({
      query: ({ serial, orgId, venue }) => ({
        url: `/organisations/${orgId}/venues/${serial}`,
        method: 'PUT',
        data: venue,
      }),
      transformResponse(data: VenueSettingsType) {
        toast.success(`${data.alias} updated`)
        return data
      },
      invalidatesTags: (data, _error, { serial, orgId }) => [
        { type: 'Organisation', id: orgId },
        { type: 'Venue', id: serial },
        { type: 'Brand', id: data?.branding_settings?.id },
      ],
    }),
    /**
     * End venue
     */

    /**
     * Reports
     */
    getTransactions: build.query<
      ServiceCursorPaginatedResponseType<OrganizationRegistrationTransactionType>,
      {
        orgId: string
        query: InteractionRequestType
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/transactions`, query),
      }),
    }),
    getTransactionTotals: build.query<
      OrganizationRegistrationTransactionTotalsType,
      {
        orgId: string
        query: InteractionRequestType
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/transactions/totals`, query),
      }),
    }),
    getInteractions: build.query<
      ServiceCursorPaginatedResponseType<InteractionType>,
      {
        orgId: string
        query: InteractionRequestType
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/interactions`, {
          ...query,
          query_type: 'basic',
        }),
      }),
    }),
    getOriginReport: build.query<
      MappedProfileItem[],
      { serial: string; start: Date; end: Date; orgId: string }
    >({
      query: ({ orgId, start, end, serial }) => ({
        url: buildUrl(`/organisations/${orgId}/crm/reports/origin`, {
          start: secondsEpoch(start.getTime()),
          end: secondsEpoch(end.getTime()),
          serial,
        }),
        method: 'get',
      }),
    }),
    /**
     * Website
     */
    getWebsites: build.query<
      WebsiteType[],
      {
        orgId: string
      }
    >({
      query: ({ orgId }) => ({
        method: 'get',
        url: `/organisations/${orgId}/website`,
      }),
      providesTags: [{ id: 'LIST', type: 'Websites' }],
    }),
    createWebsite: build.mutation<WebsiteType, { orgId: string; url: string }>({
      query: ({ orgId, url }) => ({
        url: `/organisations/${orgId}/website`,
        method: 'POST',
        data: { url },
      }),
      invalidatesTags: [{ type: 'Websites', id: 'LIST' }],
      transformResponse(data: WebsiteType) {
        toast.success(`${data.url} created`)
        return data
      },
    }),
    getWebsiteEvents: build.query<
      ServiceCursorPaginatedResponseType<WebstieEventType>,
      { cursor: string; limit: number; search?: string; orgId: string }
    >({
      query: ({ orgId, cursor, limit, search }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/website/events`, {
          search,
          limit,
          cursor,
        }),
      }),
    }),
    getWebsiteProfiles: build.query<
      ServiceCursorPaginatedResponseType<WebsiteProfileCookie>,
      { cursor: string; limit: number; search?: string; orgId: string }
    >({
      query: ({ orgId, cursor, limit, search }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/website/profiles`, {
          search,
          limit,
          cursor,
        }),
      }),
    }),
    createCsvSignedUrl: build.mutation<
      SignedCsvUrlResponseType,
      SignedCsvUrlRequestType
    >({
      query: ({
        orgId,
        serials,
        data_source,
        default_region,
        original_file_name = new Date().toTimeString(),
        tags,
      }) => ({
        method: 'post',
        url: buildUrl(`/organisations/${orgId}/csv/uploads`, {}),
        data: {
          serials,
          data_source,
          region: default_region,
          original_file_name,
          type: 'user',
          tags,
        },
      }),
    }),
    createCsvUpload: build.mutation<
      CsvUploadResponseType,
      CsvUploadRequestType
    >({
      query: ({ url, data }) => ({
        method: 'put',
        url,
        headers: {
          'Content-Type': 'text/csv',
        },
        timeout: 1000 * 60,
        data,
      }),
      transformResponse(data: CsvUploadResponseType) {
        toast.success('Csv uploaded for processing')
        return data
      },
      transformErrorResponse: (error) => {
        toast.warning('Something went wrong')
        return error
      },
    }),
    getCsvUploads: build.query<
      PaginationRepsonse<CsvUploadsFromDynamo>,
      { orgId: string; page: { offset: number; limit: number } }
    >({
      query: ({ orgId, page }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/csv/uploads`, page),
      }),
    }),
    /**
     * Loyalty start
     */

    getLoyaltySchemeUsers: build.query<
      LoyaltyDataPaginationType,
      {
        orgId: string
        schemeId: string
        cursor: PagenationRequestType
      }
    >({
      query: ({ orgId, schemeId, cursor }) => ({
        method: 'get',
        url: buildUrl(
          `/organisations/${orgId}/loyalty/${schemeId}/customers`,
          cursor
        ),
      }),
      // providesTags: (item) => [{ id: item.id, type: 'LoyaltySchemes' }],
    }),

    /**
     * Loyalty end
     */

    /**
     * Stripe
     */

    getStripeAccounts: build.query<
      {
        account_display_name: string | null
        livemode: 0 | 1
        stripe_user_id: string
        scope: 'read_write'
        id: number
        token_type: 'bearer'
      }[],
      { orgId: string }
    >({
      query: ({ orgId }) => ({
        method: 'get',
        url: `/organisations/${orgId}/integrations/stripe`,
      }),
    }),

    /**
     * Stripe end
     */

    getPayPalAccounts: build.query<
      { id: string; name: string }[],
      { orgId: string }
    >({
      query: ({ orgId }) => ({
        method: 'get',
        url: `/organisations/${orgId}/integrations/paypal`,
      }),
      providesTags: [{ type: 'PayPal', id: 'LIST' }],
    }),
    updatePayPalAccounts: build.mutation<PaypalAccountType, { orgId: string }>({
      query: ({ orgId }) => ({
        method: 'PUT',
        url: `/organisations/${orgId}/integrations/paypal`,
      }),
    }),
    createPayPalAccounts: build.mutation<
      PaypalAccountType,
      {
        orgId: string
        data: Partial<PaypalAccountType>
      }
    >({
      query: ({ orgId, data }) => ({
        method: 'POST',
        url: `/organisations/${orgId}/integrations/paypal`,
        data,
      }),
      invalidatesTags: [{ type: 'PayPal', id: 'LIST' }],
    }),

    /**
     * Loke integration
     */
    getLokeConfig: build.query<LokeIntegrationConfig, { org_id: string }>({
      query: ({ org_id }) => ({
        url: `/organisations/${org_id}/integrations/loke`,
        method: 'get',
      }),
    }),
    modifyLokeConfig: build.mutation<
      LokeIntegrationConfig,
      {
        org_id: string
        data: LokeIntegrationConfig
      }
    >({
      query: ({ org_id, data }) => ({
        url: `/organisations/${org_id}/integrations/loke`,
        method: 'put',
        data,
      }),
      invalidatesTags: (res) => {
        return [{ id: res?.id, type: 'loke-config' }]
      },
    }),

    /**
     *
     *
     */

    /**
     * ----
     * TAGS START
     * ----
     */
    getTags: build.query<
      TagType[],
      {
        orgId: string
      }
    >({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/crm/tags`,
        method: 'get',
      }),
      providesTags: (items) => [
        ...items.map((item) => ({
          id: item.id,
          type: 'ProfileTags' as const,
        })),
        { id: 'LIST', type: 'ProfileTags' },
      ],
      transformResponse: (items: TagType[]) =>
        [...items].sort((a, b) => a.name.localeCompare(b.name)),
    }),
    deleteTag: build.mutation<
      boolean,
      {
        orgId: string
        tag: TagType
      }
    >({
      query: ({ orgId, tag }) => ({
        url: `/organisations/${orgId}/crm/tags/${tag.id}`,
        method: 'delete',
      }),
      invalidatesTags: [{ id: 'LIST', type: 'ProfileTags' }],
      transformResponse: (_1, _2, { tag }) => {
        toast.success(`${tag.name} deleted`)
        return true
      },
    }),
    updateTag: build.mutation<
      TagType,
      {
        orgId: string
        tag: TagType
      }
    >({
      query: ({ orgId, tag }) => ({
        url: `/organisations/${orgId}/crm/tags/${tag.id}`,
        method: 'put',
        data: {
          name: tag.name,
        },
      }),
      invalidatesTags: [{ id: 'LIST', type: 'ProfileTags' }],
      transformResponse: (item: TagType) => {
        toast.success(`${item.name} updated`)
        return item
      },
    }),
    createTag: build.mutation<
      TagType,
      {
        orgId: string
        name: string
      }
    >({
      query: ({ orgId, name }) => ({
        url: `/organisations/${orgId}/crm/tags`,
        method: 'post',
        data: { name },
      }),
      invalidatesTags: [{ id: 'LIST', type: 'ProfileTags' }],
      transformResponse: (item: TagType) => {
        toast.success(`${item.name} created`)
        return item
      },
    }),
    /**
     * ----
     * TAGS END
     * ----
     */

    /**
     * Gifting start
     */

    getGiftCards: build.query<
      GiftSettingsType[],
      {
        orgId: string
      }
    >({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/gifting`,
        method: 'get',
      }),
      providesTags: (results) =>
        results ? [{ type: 'Gifting', id: 'LIST' }] : [],
    }),
    getGiftCard: build.query<
      GiftSettingsType,
      {
        orgId: string
        id: string
      }
    >({
      query: ({ orgId, id }) => ({
        url: `/organisations/${orgId}/gifting/schemes/${id}`,
        method: 'get',
      }),
      providesTags: (results) => [{ type: 'Gifting', id: results?.id }],
    }),

    createGiftCard: build.mutation<
      GiftSettingsType,
      {
        orgId: string
        data: Partial<GiftSettingsType>
      }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/gifting`,
        method: 'post',
        // data,
        // @todo sort this out zod/prisma schema acting up
        data: { ...data, quantities: undefined },
      }),
      transformErrorResponse() {
        toast.error('Gift card could not be created')
      },
      invalidatesTags: () => [{ type: 'Gifting', id: 'LIST' }],
    }),
    updateGiftCard: build.mutation<
      GiftSettingsType,
      {
        orgId: string
        data: GiftSettingsType
      }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/gifting/schemes/${data.id}`,
        method: 'put',
        // @todo sort this out zod/prisma schema acting up
        data: { ...data, quantities: data.quantities || undefined },
      }),
      transformErrorResponse() {
        toast.error('Gift card could not be updated')
      },
      invalidatesTags: (result) => [
        { type: 'Gifting', id: result?.id },
        { type: 'Gifting', id: 'LIST' },
      ],
    }),
    getGiftActivations: build.query<
      GiftActivationType,
      {
        orgId: string
        query: {
          cursor: string
          limit: number
          term: string
          status: GiftCardInstanceType['status'] | null
        }
      }
    >({
      query: ({ orgId, query }) => ({
        url: buildUrl(`/organisations/${orgId}/gifting/search`, query),
        method: 'get',
      }),
      transformResponse(data: GiftActivationType) {
        return (
          data ?? {
            data: [],
            links: { first: null, last: null, next: null, prev: null },
          }
        )
      },
      providesTags: (results) => [
        ...(results?.data ?? []).map((item) => ({
          type: 'GiftCardsActivations' as const,
          id: item.id,
        })),
        { type: 'GiftCardsActivations', id: 'LIST' },
      ],
    }),

    getGiftReports: build.query<
      GiftReportResponse,
      {
        orgId: string
        start: Date
        end: Date
      }
    >({
      query: ({ orgId, start, end }) => ({
        url: buildUrl(`/organisations/${orgId}/gifting/reporting`, {
          start: secondsEpoch(start.getTime()),
          end: secondsEpoch(end.getTime()),
        }),
        method: 'get',
      }),
    }),
    createGiftActivation: build.mutation<
      GiftCardInstanceType,
      {
        orgId: string
        cardId: string
        data: GiftActivationMutation
      }
    >({
      query: ({ cardId, orgId, data }) => ({
        url: `/organisations/${orgId}/gifting/cards/${cardId}`,
        method: 'post',
        data,
      }),
      transformErrorResponse() {
        toast.error('Gift card cannot be created')
      },
      invalidatesTags: () => [{ type: 'GiftCardsActivations', id: 'LIST' }],
    }),

    createGiftRefund: build.mutation<
      GiftCardInstanceType,
      {
        gift: GiftCardInstanceType
        orgId: string
      }
    >({
      query: ({ gift, orgId }) => ({
        url: `/organisations/${orgId}/gifting/cards/${gift.id}/refund`,
        method: 'post',
      }),
      transformResponse(data: GiftCardInstanceType) {
        toast.success('Gift card refunded')
        return data
      },
      transformErrorResponse() {
        toast.error('Gift card cannot be refunded')
      },
      invalidatesTags: (item) => [
        { type: 'GiftCardsActivations', id: item?.id },
      ],
    }),
    redeemUserGiftCard: build.mutation<
      GiftCardInstanceType,
      {
        id: string
        orgId: string
      }
    >({
      query: ({ id, orgId }) => ({
        url: `/organisations/${orgId}/gifting/cards/${id}/redeem`,
        method: 'post',
      }),
      transformResponse(data: GiftCardInstanceType) {
        toast.success('Gift card redeemed')
        return data
      },
      transformErrorResponse(error) {
        if (isAxiosError(error) && error?.response?.status === 403) {
          toast.error('This gift card has already been redeemed')
          return
        }
        toast.error('Gift card cannot be redeemed')
      },
      invalidatesTags: (item) => [
        { type: 'GiftCardsActivations', id: item?.id },
        { type: 'GiftCardsActivations', id: 'LIST' },
      ],
    }),
    changeGiftOwner: build.mutation<
      GiftCardInstanceType,
      {
        orgId: string
        gift: GiftCardInstanceType
        profile: CandidateProfileType
      }
    >({
      query: ({ gift, profile: data, orgId }) => ({
        url: `/organisations/${orgId}/gifting/cards/${gift.id}/owner`,
        method: 'put',
        data,
      }),
      transformResponse(data: GiftCardInstanceType) {
        toast.success('Gift card owner updated successfully')
        return data
      },
      transformErrorResponse() {
        toast.error('Could not change owner of card')
      },
      invalidatesTags: (item) => [
        { type: 'GiftCardsActivations', id: item?.id },
      ],
    }),

    /**
     * Gifting end
     */

    getTempOrgReg: build.query<
      { org_reg_id: string },
      {
        orgId: string
        core_id: string
      }
    >({
      query: ({ orgId, core_id }) => ({
        url: `/organisations/${orgId}/temporary/org-reg?core_id=${core_id}`,
        method: 'get',
      }),
    }),

    /**
     *  Admin
     */

    getOrgRegCounts: build.query<
      {
        data: {
          id: string
          organization_id: string
          segment_id: string | null
          data_count: null
          email_count: null
          sms_count: null
          subscription_active: boolean
          subscription_allowance: number
          subscription_allowance_used: string
          created_at: string
        }[]
        next_cursor: string | null
      },
      { allowance_gte: number; cursor: string }
    >({
      query: ({ allowance_gte, cursor }) => ({
        url: `/admin/organisation-registration-counts?allowance_gte=${allowance_gte}&cursor=${cursor}`,
        method: 'get',
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return JSON.stringify({
          allowance_gte: queryArgs.allowance_gte,
          cursor: '',
        })
      },
      merge: (currentCache, newItems) => {
        currentCache.next_cursor = newItems.next_cursor

        newItems.data.forEach((item) => {
          const current = currentCache.data.findIndex((it) => it.id === item.id)
          if (current >= 0) {
            currentCache.data[current] = item
          } else {
            currentCache.data.push(item)
          }
        })
      },

      forceRefetch({ currentArg, previousArg }) {
        return (
          !isEqual(currentArg?.cursor, previousArg?.cursor) ||
          !isEqual(currentArg?.allowance_gte, previousArg?.allowance_gte)
        )
      },
    }),
    getRecursiveRegCounts: build.query<
      {
        data: {
          id: string
          organization_id: string
          segment_id: string | null
          data_count: null
          email_count: null
          sms_count: null
          subscription_active: boolean
          subscription_allowance: number
          subscription_allowance_used: string
          created_at: string
        }[]
        next_cursor: string | null
      },
      { allowance_gte: number; cursor: string }
    >({
      query: ({ allowance_gte, cursor }) => ({
        url: `/admin/organisation-registration-recursive-counts?allowance_gte=${allowance_gte}&cursor=${cursor}`,
        method: 'get',
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return JSON.stringify({
          allowance_gte: queryArgs.allowance_gte,
          cursor: '',
        })
      },
      merge: (currentCache, newItems) => {
        currentCache.next_cursor = newItems.next_cursor

        newItems.data.forEach((item) => {
          const current = currentCache.data.findIndex((it) => it.id === item.id)
          if (current >= 0) {
            currentCache.data[current] = item
          } else {
            currentCache.data.push(item)
          }
        })
      },

      forceRefetch({ currentArg, previousArg }) {
        return (
          !isEqual(currentArg?.cursor, previousArg?.cursor) ||
          !isEqual(currentArg?.allowance_gte, previousArg?.allowance_gte)
        )
      },
    }),
  }),
})

export default morpheusApi

import { notificationApi } from '@/state/notification/notification.slice'
import {
  getToken as getFbToken,
  getMessaging,
  onMessage,
} from 'firebase/messaging'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useFirebaseApp } from 'reactfire'
import { useLocalStorage } from 'usehooks-ts'
import { z } from 'zod'
import { useAlertContext } from '../alerts'
import { toast } from 'sonner'

function AppNotifications() {
  const [hasPermission, setHasPermission] = useState(false)
  const [alerts, setAlerts] = useAlertContext()

  const app = useFirebaseApp()
  const messaging = getMessaging(app)
  const checkPermission = useCallback(async () => {
    try {
      await Notification.requestPermission()
      setHasPermission(true)
    } catch {
      setHasPermission(false)
    }
  }, [])

  useLayoutEffect(() => {
    checkPermission()
  }, [checkPermission])

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (newMessage) => {
      const shape = z
        .object({
          notification: z.object({
            title: z.string(),
            body: z.string(),
          }),
          fcmOptions: z.object({
            link: z.string(),
          }),
          messageId: z.string(),
        })
        .safeParse(newMessage)

      if (shape.success) {
        const { notification, fcmOptions, messageId } = shape.data
        const url = new URL(fcmOptions.link)

        setAlerts({
          itemKey: messageId,
          title: notification.title,
          type: 'info',
          buttonText: 'View',
          to: url.pathname,
          isDismissible: true,
        })

        toast.info(notification.title, {
          description: notification.body,
          action: {
            onClick: () => {
              window.location.href = url.pathname
            },
            label: 'View',
          },
        })
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  const [permissionToken, setPermissionToken] = useLocalStorage<string | null>(
    'device-notification-token',
    null
  )

  notificationApi.useGetBrowserTokenQuery(
    {
      token: permissionToken!,
    },
    {
      skip: !permissionToken,
    }
  )

  const getToken = useCallback(async () => {
    try {
      const messaging = getMessaging(app)

      const token = await getFbToken(messaging, {
        vapidKey:
          'BCYH4lJYo9mGsIDjN07MuE3CaGrLkdhvRgWMHRfUTjpujclg2Yr-kL9Tguv-7Wfp7Vn1fwTDns-4aLtGK1RqSmU',
      })

      setPermissionToken(token)
    } catch (error) {
      setPermissionToken(null)
    }
  }, [app, setPermissionToken])

  useEffect(() => {
    // if (!hasPermission || import.meta.env.NODE_ENV === 'development') return
    if (!hasPermission) return

    getToken()
  }, [getToken, hasPermission])

  return <></>
}

export default AppNotifications

import type { AxiosError } from 'axios'
import { toast } from 'sonner'
import { fromError } from 'zod-validation-error'
import {
  isAjvError,
  isAxiosError,
  isBackendError,
  isMorpheusError,
  isServiceError,
  isZionError,
  isZodError,
} from '@/utils/axiosHelper'
import { getFirst } from '@/utils/common'
import { extractZodError } from '@/helpers/zod'

export interface BackendResponseType<T> {
  message: T
  status: number
}

export interface ServiceLinkType {
  url: string | null
  label: string
  active: boolean
}

export interface CursorSearchType {
  cursor?: string | null
}

export interface PagingLinksType {
  first: string //'http://service.stg.stampede.ai/3c6f05e5-5336-11ea-9472-06a4d6597160/marketing/email-addresses?page=1'
  last: string //'http://service.stg.stampede.ai/3c6f05e5-5336-11ea-9472-06a4d6597160/marketing/email-addresses?page=1'
  prev: string | null
  next: string | null
  current?: string | null
}

export interface ServiceCursorPaginatedResponseType<T> {
  data: T[]
  links: PagingLinksType
  meta: {
    path: string //'http://service.stg.stampede.ai/3c6f05e5-5336-11ea-9472-06a4d6597160/marketing/email-addresses'
    per_page: number

    next_cursor: string | null
    prev_cursor: string | null

    total?: number
  }
  isLoading?: boolean
}
export interface ServicePaginatedResponseType<T> {
  data: T[]
  links: PagingLinksType
  meta?: {
    current_page: number
    from: number
    last_page: number
    links: ServiceLinkType[]
    path: string //'http://service.stg.stampede.ai/3c6f05e5-5336-11ea-9472-06a4d6597160/marketing/email-addresses'
    per_page: number
    to: number
    total: number

    /**
     *
     */
    next_cursor: string | null
    prev_cursor: string | null
  }
  isLoading?: boolean
}

export interface FlatServiceCursorPaginatedResponseType<T> {
  data: T[]
  path: string //'http://service.stg.stampede.ai/3c6f05e5-5336-11ea-9472-06a4d6597160/marketing/email-addresses'
  per_page: number
  next_page_url: string
  prev_page_url: string
  next_cursor: string | null
  prev_cursor: string | null
  total?: number
  isLoading?: boolean
}

export interface ServiceErrorType {
  message: string
  errors?: Record<string, string[]>
  line?: number
  file?: string
}

export interface ApiErrorType {
  detail: string
  instance: string
  status: number
  type: string
  title?: string
}

export interface AvjErrorType {
  context: string
  error: string
}

const ServiceErrorResponse = (error: AxiosError<ServiceErrorType>) => {
  const { data } = error.response
  if (data.errors && Array.isArray(Object.keys(data.errors))) {
    const arrayOfErrors = Object.keys(data.errors).map((index) =>
      data.errors[index].join(', ')
    )
    return toast.warning(data.message, {
      description: arrayOfErrors.join(', '),
      duration: 10000,
    })
  }
  if (data.message) {
    return toast.error(data.message, {
      duration: 10000,
    })
  }
}

export function ServiceErrorHandler(error: Error | unknown) {
  if (!isAxiosError(error)) {
    return
  }

  if (!error.response) {
    return
  }
  if (error.response?.status >= 500) {
    return
  }
  if (
    isMorpheusError<{ message: string | { instancePath: string }[] }>(error)
  ) {
    if (Array.isArray(error.response.data.message)) {
      if ('instancePath' in getFirst(error.response.data.message)) {
        return toast.warning('Validation error', {
          description: error.response.data.message
            .map((item) => item.instancePath)
            .join(', '),
          duration: 10000,
        })
      }
      if (isZodError(error)) {
        const validationError = fromError(extractZodError(error))
        toast.error(validationError.message)
        return
      }
    }

    if (typeof error.response.data.message === 'string') {
      return toast.error(error.response.data.message, {
        duration: 10000,
      })
    }
    return
  }
  if (isAjvError<AvjErrorType>(error)) {
    return toast.error(error.response.data.error, {
      duration: 10000,
    })
  }
  if (isBackendError<ApiErrorType>(error)) {
    return toast.error(error.response.data.detail, {
      duration: 10000,
    })
  }

  if (isZionError(error)) {
    if (Array.isArray(Object.entries(error.response.data.errors))) {
      toast.error(
        Object.entries(error.response.data.errors)
          .reduce((acc: string[], [key, value]) => {
            return [
              ...acc,
              `${key}: ${typeof value === 'string' ? value : 'Field invalid'}`,
            ]
          }, [])
          .join('; ')
      )
      return
    }
  }

  if (isServiceError<ServiceErrorType>(error)) {
    if (error.response.data.message === 'SUBSCRIPTION.NOT_ACTIVE') return null
    return ServiceErrorResponse(error)
  }
}

export const defaultServicePagination: ServicePaginatedResponseType<undefined> =
  {
    isLoading: false,
    data: [],
    links: {
      first: '',
      last: '',
      prev: '',
      next: '',
    },
    meta: {
      current_page: 1,
      from: 0,
      last_page: 0,
      links: [],
      path: '',
      per_page: 25,
      to: 0,
      total: 0,
      prev_cursor: null,
      next_cursor: null,
    },
  }

export function initServicePagination<
  T,
>(): ServiceCursorPaginatedResponseType<T> {
  return {
    isLoading: false,
    data: [],
    links: {
      first: '',
      last: '',
      prev: '',
      next: '',
    },
    meta: {
      path: '',
      per_page: 25,
      total: 0,
      prev_cursor: null,
      next_cursor: null,
    },
  }
}
